import React from "react";

import { Link } from "react-scroll";

const ScrollLink = ({ navbarData }) => {
  return (
    <>
      <li className="ud-text-body-color-2 dark:ud-text-body-color">
        {navbarData.map((navItem) => (
          <Link
            key={navItem.id}
            to={navItem.to}
            spy={true}
            className="scroll-menu ud-flex ud-py-2 ud-text-lg ud-font-semibold ud-text-body-color-2 hover:ud-text-primary dark:ud-text-body-color dark:hover:ud-text-white lg:ud-ml-7 lg:ud-inline-flex lg:ud-py-5 xl:ud-ml-10 2xl:ud-ml-12 ud-cursor-pointer"
          >
            {navItem.title}
          </Link>
        ))}
      </li>
    </>
  );
};

export default ScrollLink;
