import React from "react";
import Breadcrumb from "../../share/Breadcrumb/Breadcrumb";
import Newsletter from "../../share/Newsletter/Newsletter";
import Contact from "./Contact";
import { contactData } from "./contactData";

const Support = () => {
  document.title = "Support - Crypto React";

  return (
    <>
      <Breadcrumb />
      <Contact data={contactData} />
      <Newsletter />
    </>
  );
};

export default Support;
