import React from "react";
import Brand from "./Brand/Brand";
import Hero from "./Hero/Hero";
import Features from "./Features/Features";
import TokenSale from "./TokenSale/TokenSale";
import Timeline from "./Timeline/Timeline";
import Team from "./Team/Team";
import Testimonial from "./Testimonial/Testimonial";
import Download from "./Download/Download";
import FaqQus from "./FaqQus/FaqQus";
import NewsBlogs from "./NewsBlogs/NewsBlogs";
import Contact from "./Contact/Contact";
import Newsletter from "../../share/Newsletter/Newsletter";
// Data Import
import { heroData } from "./Hero/heroData";
import { brandData } from "./Brand/brandData";
import { featuresData } from "./Features/featuresData";
import { tokenData } from "./TokenSale/tokenSaleData";
import { roadmapData } from "./Timeline/roadmapData";
import { teamData } from "./Team/teamData";
import { testimonialData } from "./Testimonial/testimonialData";
import { downloadData } from "./Download/downloadData";
import { faqData } from "./FaqQus/faqData";
import { newsBlogsData } from "./NewsBlogs/newsblogsData";
import { contactData } from "./Contact/contactData";

const Home = () => {
  document.title =
    "Crypto React - Crypto and ICO Web Template for React & Tailwind CSS";

  return (
    <>
      <Hero data={heroData} />
      <Brand data={brandData} />
      <Features data={featuresData} />
      <TokenSale data={tokenData} />
      <Timeline data={roadmapData} />
      <Team data={teamData} />
      <Testimonial data={testimonialData} />
      <Download data={downloadData} />
      <FaqQus data={faqData} />
      <NewsBlogs data={newsBlogsData} />
      <Contact data={contactData} />
      <Newsletter background />
    </>
  );
};

export default Home;
