/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import darkLogo from "../../../images/logo/logo-white.svg";
import lightLogo from "../../../images/logo/logo.svg";
import shape1 from "../../../images/shapes/footer-shape-2.svg";
import shape2 from "../../../images/shapes/footer-shape-1.svg";

import { BsDiscord } from "react-icons/bs";
import {
  TiSocialTwitter,
  TiSocialYoutube,
  TiSocialLinkedin,
} from "react-icons/ti";
import image1 from "../../../images/footer/blog-01.jpg";
import image2 from "../../../images/footer/blog-02.jpg";

const Footer = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <footer className="ud-relative ud-z-10 ud-pt-[120px]">
        <div className="ud-container">
          <div className="ud--mx-4 ud-flex ud-flex-wrap">
            <div className="ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-4/12 xl:ud-w-4/12">
              {/*  */}
              <div className="ud-mb-14 ud-max-w-[330px] xl:ud-mb-20">
                <a href="index.html" className="ud-mb-6 ud-inline-block">
                  <img
                    src={darkLogo}
                    alt="logo"
                    className="ud-hidden dark:ud-block"
                  />
                  <img src={lightLogo} alt="logo" className="dark:ud-hidden" />
                </a>
                <p className="ud-mb-10 ud-text-base ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                  vitae quam nec ante fringilla vel at erat convallis elit.
                </p>
                <div className="ud-flex ud-items-center ud-space-x-3">
                  {/* Social Icons */}
                  <Link
                    to="/discord"
                    className="ud-flex ud-h-10 ud-w-10 ud-items-center ud-justify-center ud-rounded-full ud-bg-light-bg ud-text-body-color-2 hover:ud-bg-primary hover:ud-text-white dark:ud-bg-dark dark:ud-text-white dark:hover:ud-bg-primary"
                  >
                    <BsDiscord size={18} />
                  </Link>
                  <Link
                    to="/twitter"
                    className="ud-flex ud-h-10 ud-w-10 ud-items-center ud-justify-center ud-rounded-full ud-bg-light-bg ud-text-body-color-2 hover:ud-bg-primary hover:ud-text-white dark:ud-bg-dark dark:ud-text-white dark:hover:ud-bg-primary"
                  >
                    <TiSocialTwitter size={25} />
                  </Link>
                  <Link
                    to="/linkedin"
                    className="ud-flex ud-h-10 ud-w-10 ud-items-center ud-justify-center ud-rounded-full ud-bg-light-bg ud-text-body-color-2 hover:ud-bg-primary hover:ud-text-white dark:ud-bg-dark dark:ud-text-white dark:hover:ud-bg-primary"
                  >
                    <TiSocialLinkedin size={25} />
                  </Link>
                  <Link
                    to="/youtube"
                    className="ud-flex ud-h-10 ud-w-10 ud-items-center ud-justify-center ud-rounded-full ud-bg-light-bg ud-text-body-color-2 hover:ud-bg-primary hover:ud-text-white dark:ud-bg-dark dark:ud-text-white dark:hover:ud-bg-primary"
                  >
                    <TiSocialYoutube size={25} />
                  </Link>
                </div>
              </div>
            </div>
            <div className="ud-w-full ud-px-4 sm:ud-w-1/2 md:ud-w-1/2 lg:ud-w-4/12 xl:ud-w-2/12">
              <div className="wow fadeInUp ud-mb-14 xl:ud-mb-20">
                <h2 className="ud-mb-5 ud-text-2xl ud-font-bold ud-text-black dark:ud-text-white">
                  Quick Links
                </h2>
                <div className="ud-space-y-3">
                  {/*  */}

                  {navQuickLink.map((item) => (
                    <Link
                      key={item.id}
                      to={item.to}
                      className="ud-block ud-text-base ud-font-medium ud-text-body-color-2 hover:ud-text-primary dark:ud-text-body-color dark:hover:ud-text-primary"
                    >
                      {item.title}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="ud-w-full ud-px-4 sm:ud-w-1/2 md:ud-w-1/2 lg:ud-w-4/12 xl:ud-w-2/12">
              <div className="wow fadeInUp ud-mb-14 xl:ud-mb-20">
                <h2 className="ud-mb-5 ud-text-2xl ud-font-bold ud-text-black dark:ud-text-white">
                  Supports
                </h2>
                <div className="ud-space-y-3">
                  {/*  */}

                  {navSupport.map((item) => (
                    <Link
                      key={item.id}
                      to={item.to}
                      className="ud-block ud-text-base ud-font-medium ud-text-body-color-2 hover:ud-text-primary dark:ud-text-body-color dark:hover:ud-text-primary"
                    >
                      {item.title}
                    </Link>
                  ))}
                </div>
              </div>
            </div>

            <div className="ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/2 xl:ud-w-4/12">
              <div
                className="wow fadeInUp ud-mb-14 xl:ud-mb-20"
                data-wow-delay="0s"
              >
                <h2 className="ud-mb-5 ud-text-2xl ud-font-bold ud-text-black dark:ud-text-white">
                  News & Post
                </h2>
                <div className="ud-space-y-3">
                  <div className="ud-flex">
                    <div className="ud-mr-5 ud-h-[75px] ud-w-full ud-max-w-[75px] ud-rounded-md">
                      <img
                        src={image1}
                        alt="post"
                        className="ud-h-full ud-w-full ud-rounded-md ud-object-cover ud-object-center"
                      />
                    </div>
                    <div>
                      <Link
                        to="#"
                        className="ud-text-base ud-font-medium ud-text-body-color-2 hover:ud-text-primary dark:ud-text-body-color dark:hover:ud-text-primary"
                      >
                        Roll Out New Features Without Hurting Loyal Users
                      </Link>
                      <p className="ud-flex ud-items-center">
                        <span className="ud-pr-1 ud-text-body-color-2 dark:ud-text-body-color">
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            className="ud-fill-current"
                          >
                            <path d="M3.25 8H4.75V9.5H3.25V8ZM13.75 3.5V14C13.75 14.3978 13.592 14.7794 13.3107 15.0607C13.0294 15.342 12.6478 15.5 12.25 15.5H1.75C0.9175 15.5 0.25 14.825 0.25 14V3.5C0.25 3.10218 0.408035 2.72064 0.68934 2.43934C0.970644 2.15804 1.35218 2 1.75 2H2.5V0.5H4V2H10V0.5H11.5V2H12.25C12.6478 2 13.0294 2.15804 13.3107 2.43934C13.592 2.72064 13.75 3.10218 13.75 3.5ZM1.75 5H12.25V3.5H1.75V5ZM12.25 14V6.5H1.75V14H12.25ZM9.25 9.5V8H10.75V9.5H9.25ZM6.25 9.5V8H7.75V9.5H6.25ZM3.25 11H4.75V12.5H3.25V11ZM9.25 12.5V11H10.75V12.5H9.25ZM6.25 12.5V11H7.75V12.5H6.25Z" />
                          </svg>
                        </span>
                        <span className="ud-text-sm ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                          Dec 18, 2025
                        </span>
                      </p>
                    </div>
                  </div>
                  <div className="ud-flex">
                    <div className="ud-mr-5 ud-h-[75px] ud-w-full ud-max-w-[75px] ud-rounded-md">
                      <img
                        src={image2}
                        alt="post"
                        className="ud-h-full ud-w-full ud-rounded-md ud-object-cover ud-object-center"
                      />
                    </div>
                    <div>
                      <Link
                        to="#"
                        className="ud-text-base ud-font-medium ud-text-body-color-2 hover:ud-text-primary dark:ud-text-body-color dark:hover:ud-text-primary"
                      >
                        Top 10 Best Cryptocurrency Blogs and Websites
                      </Link>
                      <p className="ud-flex ud-items-center">
                        <span className="ud-pr-1 ud-text-body-color-2 dark:ud-text-body-color">
                          <svg
                            width="14"
                            height="16"
                            viewBox="0 0 14 16"
                            className="ud-fill-current"
                          >
                            <path d="M3.25 8H4.75V9.5H3.25V8ZM13.75 3.5V14C13.75 14.3978 13.592 14.7794 13.3107 15.0607C13.0294 15.342 12.6478 15.5 12.25 15.5H1.75C0.9175 15.5 0.25 14.825 0.25 14V3.5C0.25 3.10218 0.408035 2.72064 0.68934 2.43934C0.970644 2.15804 1.35218 2 1.75 2H2.5V0.5H4V2H10V0.5H11.5V2H12.25C12.6478 2 13.0294 2.15804 13.3107 2.43934C13.592 2.72064 13.75 3.10218 13.75 3.5ZM1.75 5H12.25V3.5H1.75V5ZM12.25 14V6.5H1.75V14H12.25ZM9.25 9.5V8H10.75V9.5H9.25ZM6.25 9.5V8H7.75V9.5H6.25ZM3.25 11H4.75V12.5H3.25V11ZM9.25 12.5V11H10.75V12.5H9.25ZM6.25 12.5V11H7.75V12.5H6.25Z" />
                          </svg>
                        </span>
                        <span className="ud-text-sm ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                          Dec 18, 2025
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className="wow fadeInUp ud-border-t ud-border-[#F3F4F4] ud-py-7 ud-text-center dark:ud-border-[#2D2C4A]"
            data-wow-delay="0s"
          >
            <p className="ud-text-base ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
              © Crypto - all Rights Reserved, Crafted by
              <a
                href="https://uideck.com"
                rel="noopenner nofollow"
                target="_blank"
                className="hover:ud-text-primary"
              >
                UIdeck
              </a>
            </p>
          </div>
        </div>

        <div className="ud-absolute ud-top-0 ud-left-0 ud--z-10">
          <img src={shape1} alt="shape" />
        </div>

        <div className="ud-absolute ud-bottom-0 ud-right-0 ud--z-10">
          <img src={shape2} alt="shape" />
        </div>
      </footer>

      <button
        onClick={() => {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }}
        className="hover:ud-shadow-signUp back-to-top ud-fixed ud-bottom-8 ud-right-8 ud-left-auto ud-z-[999] ud-flex ud-h-10 ud-w-10 ud-items-center ud-justify-center ud-rounded-lg ud-bg-primary ud-text-white ud-shadow-md ud-transition"
      >
        <span className="ud-mt-[6px] ud-h-3 ud-w-3 ud-rotate-45 ud-border-t ud-border-l ud-border-white"></span>
      </button>
    </>
  );
};

export default Footer;

const navQuickLink = [
  {
    id: 1,
    title: "What is ico",
    to: "/what-is-ico",
  },
  {
    id: 2,
    title: "Roadmap",
    to: "/roadmap",
  },
  {
    id: 3,
    title: "Whitepaper",
    to: "/whitepaper",
  },

  {
    id: 4,
    title: "Social Network",
    to: "/social-network",
  },
  {
    id: 5,
    title: "Join Us Now",
    to: "/join-us-now",
  },
];

//
const navSupport = [
  {
    id: 1,
    title: "Setting & Privacy",
    to: "/settings",
  },
  {
    id: 2,
    title: "Help & Support",
    to: "/helps",
  },
  {
    id: 3,
    title: "Terms & Conditions",
    to: "/terms",
  },

  {
    id: 4,
    title: "24/7 Supports",
    to: "/supports",
  },
  {
    id: 5,
    title: "On Point FAQ",
    to: "/faq",
  },
];
