import React from "react";
import { Link } from "react-router-dom";

const Breadcrumb = () => {
  const path = window.location.pathname.split("/");

  return (
    <>
      <section className="ud-pt-[140px] ud-pb-24">
        <div className="ud-container">
          <div
            className="wow fadeInUp ud-rounded-lg ud-bg-light-bg ud-px-6 ud-py-5 dark:ud-bg-dark sm:ud-px-8"
            data-wow-delay="0s"
          >
            <div className="ud--mx-4 ud-flex ud-flex-wrap">
              <div className="ud-w-full ud-px-4 sm:ud-w-1/2">
                <h1 className="ud-mb-4 ud-text-xl ud-font-bold ud-text-black dark:ud-text-white sm:ud-mb-0">
                  {path.toString().toUpperCase().split(",")}
                </h1>
              </div>

              <div className="ud-w-full ud-px-4 sm:ud-w-1/2">
                <ul className="ud-flex ud-items-center sm:ud-justify-end">
                  <li className="ud-text-base ud-font-medium ud-text-black dark:ud-text-white">
                    <Link to="/" className="hover:ud-text-primary">
                      Home
                    </Link>
                    <span className="ud-px-[10px]"> / </span>
                  </li>
                  <li className="ud-text-base ud-font-medium ud-text-black dark:ud-text-white">
                    {path.toString().split(",")}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Breadcrumb;
