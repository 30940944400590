import React from "react";
import shape1 from "../../../../images/shapes/timeline.svg";
import TimeLineCard from "./TimeLineCard";

const Timeline = ({ data }) => {
  const { timeLineCard, title, subTitle, description } = data;
  return (
    <>
      <section id="roadmap" className="ud-relative ud-z-10 ud-py-[120px]">
        <div className="ud-container">
          <div className="ud-mx-auto ud-mb-16 ud-max-w-[590px] ud-text-center md:ud-mb-20">
            <span className="ud-mb-3 ud-text-lg ud-font-bold ud-uppercase ud-text-primary sm:ud-text-xl">
              {subTitle}
            </span>
            <h2 className="ud-mb-3 ud-text-3xl ud-font-bold ud-leading-tight ud-text-black dark:ud-text-white md:ud-text-[45px]">
              {title}
            </h2>
            <p className="ud-text-lg ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
              {description}
            </p>
          </div>

          <div className="ud--mx-4 ud-flex ud-justify-center">
            <div className="ud-w-full ud-px-4 lg:ud-w-10/12 xl:ud-w-9/12">
              <div className="ud-relative ud--mx-4 ud-flex ud-flex-wrap md:ud-py-14 lg:ud-py-20">
                <span className="ud-absolute ud-top-0 ud-left-2 ud-hidden ud-h-full ud-w-[2px] ud-bg-light-bg dark:ud-bg-[#2D2C4A] md:ud-left-1/2 md:ud-block"></span>
                {timeLineCard.map((card) => (
                  <TimeLineCard key={card.id} card={card} />
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="ud-absolute ud-left-0 ud-bottom-0 ud--z-10">
          <img src={shape1} alt="shape" />
        </div>
      </section>
    </>
  );
};

export default Timeline;
