import React from "react";
import { Link } from "react-router-dom";

const Details = ({ data }) => {
  const {
    title,
    publisher,
    date,
    image,
    article,
    subtitle,
    subPost,
    shape1,
    shape2,
    features,
    tags,
    relatedPost,
    popularCatagory: popularCategory,
    popularTag,
    socialIcon,
  } = data;
  return (
    <>
      <section id="blog" className="ud-pb-20">
        <div className="ud-container">
          <div className="ud--mx-4 ud-flex ud-flex-wrap">
            <div className="ud-w-full ud-px-4 lg:ud-w-4/12">
              <div
                className="wow fadeInUp ud-mt-12 ud-mb-10 ud-rounded-md ud-bg-light-bg ud-p-6 dark:ud-bg-dark lg:ud-mt-0"
                data-wow-delay="0s"
              >
                <form className="ud-flex ud-items-center ud-justify-between">
                  <input
                    type="text"
                    placeholder="Search here..."
                    className="ud-placeholder-body-color ud-mr-5 ud-w-full ud-rounded-full ud-border ud-border-transparent ud-py-3 ud-px-5 ud-text-base ud-font-medium ud-text-body-color ud-outline-none focus:ud-border-primary dark:ud-bg-white dark:ud-bg-opacity-10"
                  />
                  <button className="ud-flex ud-h-[50px] ud-w-full ud-max-w-[50px] ud-items-center ud-justify-center ud-rounded-full ud-bg-primary ud-text-white">
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M19.4062 16.8125L13.9375 12.375C14.9375 11.0625 15.5 9.46875 15.5 7.78125C15.5 5.75 14.7188 3.875 13.2812 2.4375C10.3438 -0.5 5.5625 -0.5 2.59375 2.4375C1.1875 3.84375 0.40625 5.75 0.40625 7.75C0.40625 9.78125 1.1875 11.6562 2.625 13.0937C4.09375 14.5625 6.03125 15.3125 7.96875 15.3125C9.875 15.3125 11.75 14.5938 13.2188 13.1875L18.75 17.6562C18.8438 17.75 18.9688 17.7812 19.0938 17.7812C19.25 17.7812 19.4062 17.7188 19.5312 17.5938C19.6875 17.3438 19.6562 17 19.4062 16.8125ZM3.375 12.3438C2.15625 11.125 1.5 9.5 1.5 7.75C1.5 6 2.15625 4.40625 3.40625 3.1875C4.65625 1.9375 6.3125 1.3125 7.96875 1.3125C9.625 1.3125 11.2812 1.9375 12.5312 3.1875C13.75 4.40625 14.4375 6.03125 14.4375 7.75C14.4375 9.46875 13.7188 11.125 12.5 12.3438C10 14.8438 5.90625 14.8438 3.375 12.3438Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </form>
              </div>
              <div
                className="wow fadeInUp ud-mb-10 ud-rounded-md ud-bg-light-bg dark:ud-bg-dark"
                data-wow-delay="0s"
              >
                <h3 className="ud-border-b ud-border-body-color ud-border-opacity-10 ud-py-4 ud-px-8 ud-text-lg ud-font-semibold ud-text-black dark:ud-border-white dark:ud-border-opacity-10 dark:ud-text-white">
                  {relatedPost.title}
                </h3>
                <ul className="ud-p-8">
                  {relatedPost.post.map((post) => (
                    <li
                      key={post.id}
                      className="ud-mb-6 ud-border-b ud-border-body-color ud-border-opacity-10 ud-pb-6 dark:ud-border-white dark:ud-border-opacity-10"
                    >
                      <div
                        key={post.id}
                        className="ud-flex ud-items-center lg:ud-block xl:ud-flex"
                      >
                        <div className="ud-mr-5 ud-h-[75px] ud-w-full ud-max-w-[85px] ud-overflow-hidden ud-rounded-md lg:ud-mb-3 xl:ud-mb-0">
                          <img src={post.img1} alt="Post_image" />
                        </div>
                        <div className="ud-w-full">
                          <h5>
                            <Link
                              to="#"
                              className="ud-mb-[6px] ud-block ud-text-base ud-font-medium ud-leading-snug ud-text-black hover:ud-text-primary dark:ud-text-white dark:hover:ud-text-primary"
                            >
                              {post.title}
                            </Link>
                          </h5>
                          <p className="ud-text-xs ud-font-medium ud-text-body-color-2">
                            {post.date}
                          </p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className="wow fadeInUp ud-mb-10 ud-rounded-md ud-bg-light-bg dark:ud-bg-dark"
                data-wow-delay="0s"
              >
                <h3 className="ud-border-b ud-border-body-color ud-border-opacity-10 ud-py-4 ud-px-8 ud-text-lg ud-font-semibold ud-text-black dark:ud-border-white dark:ud-border-opacity-10 dark:ud-text-white">
                  Popular Category
                </h3>
                <ul className="ud-py-6 ud-px-8">
                  {popularCategory.map((item) => (
                    <li key={item + " "}>
                      <Link
                        to="#"
                        className="ud-mb-3 ud-inline-block ud-text-base ud-font-medium ud-text-body-color-2 hover:ud-text-primary dark:ud-text-body-color dark:hover:ud-text-primary"
                      >
                        {item}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className="wow fadeInUp ud-mb-10 ud-rounded-md ud-bg-light-bg dark:ud-bg-dark"
                data-wow-delay="0s"
              >
                <h3 className="ud-border-b ud-border-body-color ud-border-opacity-10 ud-py-4 ud-px-8 ud-text-lg ud-font-semibold ud-text-black dark:ud-border-white dark:ud-border-opacity-10 dark:ud-text-white">
                  Popular Tags
                </h3>
                <ul className="ud-flex ud-flex-wrap ud-py-6 ud-px-8">
                  {popularTag.map((tag) => (
                    <li key={tag}>
                      <Link
                        to="#"
                        className="ud-text-body-color-3 ud-mr-3 ud-mb-3 ud-inline-flex ud-items-center ud-justify-center ud-rounded-full ud-border-[.5px] ud-border-body-color ud-bg-body-color ud-bg-opacity-10 ud-py-2 ud-px-4 hover:ud-border-primary hover:ud-bg-primary hover:ud-text-white dark:ud-border-[#363D68] dark:ud-bg-[#272E5C] dark:ud-text-white dark:hover:ud-border-primary dark:hover:ud-bg-primary dark:hover:ud-text-white"
                      >
                        {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="ud-w-full ud-px-4 lg:ud-w-8/12">
              <div>
                <h2
                  className="wow fadeInUp ud-mb-8 ud-text-3xl ud-font-bold ud-leading-tight ud-text-black dark:ud-text-white sm:ud-text-4xl sm:ud-leading-tight"
                  data-wow-delay="0s"
                >
                  {title}
                </h2>
                <div
                  className="wow fadeInUp ud-mb-10 ud-flex ud-flex-wrap ud-items-center ud-justify-between ud-border-b ud-border-body-color ud-border-opacity-10 ud-pb-4 dark:ud-border-white dark:ud-border-opacity-10"
                  data-wow-delay="0s"
                >
                  <div className="ud-flex ud-flex-wrap ud-items-center">
                    <div className="ud-mb-2 ud-flex ud-items-center">
                      <p className="ud-mr-3 ud-flex ud-items-center ud-text-body-color-2 dark:ud-text-body-color">
                        <span className="ud-pr-2 ud-text-dark dark:ud-text-white">
                          <svg
                            width="22"
                            height="22"
                            viewBox="0 0 22 22"
                            className="ud-fill-current"
                          >
                            <path d="M11.0001 3.66675C11.9725 3.66675 12.9052 4.05306 13.5928 4.74069C14.2804 5.42832 14.6667 6.36095 14.6667 7.33341C14.6667 8.30587 14.2804 9.2385 13.5928 9.92614C12.9052 10.6138 11.9725 11.0001 11.0001 11.0001C10.0276 11.0001 9.09499 10.6138 8.40736 9.92614C7.71972 9.2385 7.33341 8.30587 7.33341 7.33341C7.33341 6.36095 7.71972 5.42832 8.40736 4.74069C9.09499 4.05306 10.0276 3.66675 11.0001 3.66675ZM11.0001 12.8334C15.0517 12.8334 18.3334 14.4742 18.3334 16.5001V18.3334H3.66675V16.5001C3.66675 14.4742 6.94841 12.8334 11.0001 12.8334Z" />
                          </svg>
                        </span>
                        {publisher}
                      </p>
                      <p className="ud-flex ud-items-center ud-text-body-color-2 dark:ud-text-body-color">
                        <span className="ud-pr-2 ud-text-dark dark:ud-text-white">
                          <svg
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            className="ud-fill-current"
                          >
                            <path d="M6.125 10.5H7.875V12.25H6.125V10.5ZM18.375 5.25V17.5C18.375 17.9641 18.1906 18.4092 17.8624 18.7374C17.5342 19.0656 17.0891 19.25 16.625 19.25H4.375C3.40375 19.25 2.625 18.4625 2.625 17.5V5.25C2.625 4.78587 2.80937 4.34075 3.13756 4.01256C3.46575 3.68437 3.91087 3.5 4.375 3.5H5.25V1.75H7V3.5H14V1.75H15.75V3.5H16.625C17.0891 3.5 17.5342 3.68437 17.8624 4.01256C18.1906 4.34075 18.375 4.78587 18.375 5.25ZM4.375 7H16.625V5.25H4.375V7ZM16.625 17.5V8.75H4.375V17.5H16.625ZM13.125 12.25V10.5H14.875V12.25H13.125ZM9.625 12.25V10.5H11.375V12.25H9.625ZM6.125 14H7.875V15.75H6.125V14ZM13.125 15.75V14H14.875V15.75H13.125ZM9.625 15.75V14H11.375V15.75H9.625Z" />
                          </svg>
                        </span>
                        {date}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <p
                    className="wow fadeInUp ud-mb-10 ud-text-base ud-font-medium ud-leading-relaxed ud-text-body-color-2 dark:ud-text-body-color sm:ud-text-lg sm:ud-leading-relaxed lg:ud-text-base lg:ud-leading-relaxed xl:ud-text-lg xl:ud-leading-relaxed"
                    data-wow-delay="0s"
                  >
                    {article.slice(0, 125)}.
                  </p>
                  <div
                    className="wow fadeInUp ud-mb-10 ud-w-full ud-overflow-hidden ud-rounded-lg"
                    data-wow-delay="0s"
                  >
                    <img
                      src={image}
                      alt="Blog_Image"
                      className="ud-h-full ud-w-full ud-rounded-lg ud-object-cover ud-object-center"
                    />
                  </div>
                  <p
                    className="wow fadeInUp ud-mb-8 ud-text-base ud-font-medium ud-leading-relaxed ud-text-body-color-2 dark:ud-text-body-color sm:ud-text-lg sm:ud-leading-relaxed lg:ud-text-base lg:ud-leading-relaxed xl:ud-text-lg xl:ud-leading-relaxed"
                    data-wow-delay="0s"
                  >
                    {article.slice(0, 450)}
                  </p>
                  <p
                    className="wow fadeInUp ud-mb-10 ud-text-base ud-font-medium ud-leading-relaxed ud-text-body-color-2 dark:ud-text-body-color sm:ud-text-lg sm:ud-leading-relaxed lg:ud-text-base lg:ud-leading-relaxed xl:ud-text-lg xl:ud-leading-relaxed"
                    data-wow-delay="0s"
                  >
                    {article.slice(0, 50) + " "}
                    <span className="ud-text-primary ud-underline dark:ud-text-white">
                      {article.slice(0, 20) + " "}
                    </span>
                    {article.slice(0, 200)}
                  </p>
                  <h3
                    className="ud-font-xl wow fadeInUp ud-mb-10 ud-font-bold ud-leading-tight ud-text-black dark:ud-text-white sm:ud-text-2xl sm:ud-leading-tight lg:ud-text-xl lg:ud-leading-tight xl:ud-text-2xl xl:ud-leading-tight"
                    data-wow-delay="0s"
                  >
                    {subtitle}
                  </h3>
                  <p
                    className="wow fadeInUp ud-mb-10 ud-text-base ud-font-medium ud-leading-relaxed ud-text-body-color-2 dark:ud-text-body-color sm:ud-text-lg sm:ud-leading-relaxed lg:ud-text-base lg:ud-leading-relaxed xl:ud-text-lg xl:ud-leading-relaxed"
                    data-wow-delay="0s"
                  >
                    {subPost.slice(0, 200)}
                  </p>
                  <ul
                    className="wow fadeInUp ud-mb-10 ud-list-inside ud-list-disc ud-text-body-color"
                    data-wow-delay="0s"
                  >
                    {features.map((li) => (
                      <li
                        key={li + ""}
                        className="ud-mb-2 ud-text-base ud-font-medium ud-text-body-color-2 dark:ud-text-body-color sm:ud-text-lg lg:ud-text-base xl:ud-text-lg"
                      >
                        {li}
                      </li>
                    ))}
                  </ul>
                  <div
                    className="wow fadeInUp ud-relative ud-z-10 ud-mb-10 ud-overflow-hidden ud-rounded-md ud-bg-primary ud-bg-opacity-10 ud-p-8 md:ud-p-9 lg:ud-p-8 xl:ud-p-9"
                    data-wow-delay="0s"
                  >
                    <p className="ud-text-center ud-text-base ud-font-medium ud-italic ud-text-body-color-2 dark:ud-text-body-color">
                      {subPost.slice(0, 200)}
                    </p>
                    <span className="ud-absolute ud-left-0 ud-top-0 ud-z-[-1]">
                      <img src={shape1} alt="shape" />
                    </span>
                    <span className="ud-absolute ud-right-0 ud-bottom-0 ud-z-[-1]">
                      <img src={shape2} alt="shape" />
                    </span>
                  </div>
                  <p
                    className="wow fadeInUp ud-mb-10 ud-text-base ud-font-medium ud-leading-relaxed ud-text-body-color-2 dark:ud-text-body-color sm:ud-text-lg sm:ud-leading-relaxed lg:ud-text-base lg:ud-leading-relaxed xl:ud-text-lg xl:ud-leading-relaxed"
                    data-wow-delay="0s"
                  >
                    {subPost.slice(46, 200)}
                  </p>
                  <div
                    className="wow fadeInUp ud-items-center ud-justify-between sm:ud-flex"
                    data-wow-delay="0s"
                  >
                    <div className="ud-mb-5">
                      <h5 className="ud-mb-3 ud-text-sm ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                        Popular Tags :
                      </h5>
                      <div className="ud-flex ud-items-center">
                        {tags.map((tag) => (
                          <Link
                            key={tag + ""}
                            to="#"
                            className="ud-mr-4 ud-inline-flex ud-items-center ud-justify-center ud-rounded-full ud-bg-light-bg ud-py-2 ud-px-4 ud-text-body-color-2 hover:ud-bg-primary hover:ud-text-white dark:ud-bg-dark dark:ud-text-body-color dark:hover:ud-bg-primary dark:hover:ud-text-white"
                          >
                            {tag}
                          </Link>
                        ))}
                      </div>
                    </div>
                    <div className="ud-mb-5">
                      <h5 className="ud-mb-3 ud-text-sm ud-font-medium ud-text-body-color sm:ud-text-right">
                        Share this post :
                      </h5>
                      <div className="ud-flex ud-items-center sm:ud-justify-end">
                        {socialIcon.map((icon) => (
                          <Link
                            key={icon.id}
                            to={icon.href}
                            className="ud-inline-flex ud-h-9 ud-w-9 ud-items-center ud-justify-center ud-rounded-full ud-bg-light-bg ud-text-body-color-2 hover:ud-bg-primary hover:ud-text-white dark:ud-bg-dark dark:ud-text-body-color dark:hover:ud-bg-primary dark:hover:ud-text-white sm:ud-ml-3"
                          >
                            {icon.icon}
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Details;
