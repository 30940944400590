import React from "react";
import Breadcrumb from "../../share/Breadcrumb/Breadcrumb";
import Newsletter from "../../share/Newsletter/Newsletter";
import Details from "./Details";

import { blogDetailsData } from "./blogDetailsData";

const BlogDetails = () => {
  document.title = "Blog Details - Crypto React";
  return (
    <>
      <Breadcrumb />
      <Details data={blogDetailsData} />
      <Newsletter />
    </>
  );
};

export default BlogDetails;
