/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Pagination = () => {
  return (
    <>
      <div className="ud-mx-[-16px] ud-flex ud-flex-wrap">
        <div className="ud-w-full ud-px-4">
          <ul
            className="wow fadeInUp ud-flex ud-items-center ud-justify-center ud-pt-8"
            data-wow-delay="0s"
          >
            <li className="ud-mx-1">
              <a
                href=""
                className="ud-flex ud-h-9 ud-min-w-[36px] ud-items-center ud-justify-center ud-rounded-md ud-bg-body-color ud-bg-opacity-[25%] ud-px-4 ud-text-sm ud-text-body-color-2 ud-transition hover:ud-bg-primary hover:ud-bg-opacity-100 hover:ud-text-white dark:ud-bg-dark dark:ud-text-body-color dark:hover:ud-bg-primary dark:hover:ud-text-white"
              >
                Prev
              </a>
            </li>
            <li className="ud-mx-1">
              <a
                href=""
                className="ud-flex ud-h-9 ud-min-w-[36px] ud-items-center ud-justify-center ud-rounded-md ud-bg-body-color ud-bg-opacity-[25%] ud-px-4 ud-text-sm ud-text-body-color-2 ud-transition hover:ud-bg-primary hover:ud-bg-opacity-100 hover:ud-text-white dark:ud-bg-dark dark:ud-text-body-color dark:hover:ud-bg-primary dark:hover:ud-text-white"
              >
                1
              </a>
            </li>
            <li className="ud-mx-1">
              <a
                href=""
                className="ud-flex ud-h-9 ud-min-w-[36px] ud-items-center ud-justify-center ud-rounded-md ud-bg-body-color ud-bg-opacity-[25%] ud-px-4 ud-text-sm ud-text-body-color-2 ud-transition hover:ud-bg-primary hover:ud-bg-opacity-100 hover:ud-text-white dark:ud-bg-dark dark:ud-text-body-color dark:hover:ud-bg-primary dark:hover:ud-text-white"
              >
                2
              </a>
            </li>
            <li className="ud-mx-1">
              <a
                href=""
                className="ud-flex ud-h-9 ud-min-w-[36px] ud-items-center ud-justify-center ud-rounded-md ud-bg-body-color ud-bg-opacity-[25%] ud-px-4 ud-text-sm ud-text-body-color-2 ud-transition hover:ud-bg-primary hover:ud-bg-opacity-100 hover:ud-text-white dark:ud-bg-dark dark:ud-text-body-color dark:hover:ud-bg-primary dark:hover:ud-text-white"
              >
                3
              </a>
            </li>
            <li className="ud-mx-1">
              <a
                href=""
                className="ud-flex ud-h-9 ud-min-w-[36px] ud-cursor-not-allowed ud-items-center ud-justify-center ud-rounded-md ud-bg-body-color ud-bg-opacity-[25%] ud-px-4 ud-text-sm ud-text-body-color dark:ud-bg-dark"
              >
                ...
              </a>
            </li>
            <li className="ud-mx-1">
              <a
                href=""
                className="ud-flex ud-h-9 ud-min-w-[36px] ud-items-center ud-justify-center ud-rounded-md ud-bg-body-color ud-bg-opacity-[25%] ud-px-4 ud-text-sm ud-text-body-color-2 ud-transition hover:ud-bg-primary hover:ud-bg-opacity-100 hover:ud-text-white dark:ud-bg-dark dark:ud-text-body-color dark:hover:ud-bg-primary dark:hover:ud-text-white"
              >
                12
              </a>
            </li>
            <li className="ud-mx-1">
              <a
                href=""
                className="ud-flex ud-h-9 ud-min-w-[36px] ud-items-center ud-justify-center ud-rounded-md ud-bg-body-color ud-bg-opacity-[25%] ud-px-4 ud-text-sm ud-text-body-color-2 ud-transition hover:ud-bg-primary hover:ud-bg-opacity-100 hover:ud-text-white dark:ud-bg-dark dark:ud-text-body-color dark:hover:ud-bg-primary dark:hover:ud-text-white"
              >
                Next
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Pagination;
