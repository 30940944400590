import React from "react";
import { signupData } from "./signupData";
import SignupSection from "./SignupSection";
const Signup = () => {
  document.title = "Sign Up - Crypto React";

  return (
    <>
      <SignupSection data={signupData} />
    </>
  );
};

export default Signup;
