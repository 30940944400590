import image1 from "../../../../images/testimonials/image-01.jpg";
import image2 from "../../../../images/testimonials/image-02.jpg";
import image3 from "../../../../images/testimonials/image-03.jpg";
import image4 from "../../../../images/testimonials/image-04.jpg";

export const testimonialData = {
  subTitle: "TESTIMONIALS",
  title: "What Our Client Say's",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condimentum vel.",

  testimonialCard: [
    {
      id: "1",
      image: image1,
      name: "Jason Keys",
      profession: "CEO & Founder @ Dreampeet.",
      review:
        "“I believe in lifelong learning and Learn. is a great place to learn from experts. I've learned a lot and recommend it to all my friends and familys.”",
    },
    {
      id: "2",
      image: image2,
      name: "Mariya Merry",
      profession: "CEO & Founder @ Betex.",
      review:
        "“I believe in lifelong learning and Learn. is a great place to learn from experts. I've learned a lot and recommend it to all my friends and familys.”",
    },
    {
      id: "3",
      image: image3,
      name: "Andria Jolly",
      profession: "CEO & Founder @ CryptoX.",
      review:
        "“I believe in lifelong learning and Learn. is a great place to learn from experts. I've learned a lot and recommend it to all my friends and familys.”",
    },
    {
      id: "4",
      image: image4,
      name: "Devid Willium",
      profession: "CEO & Founder @ Coinbase.",
      review:
        "“I believe in lifelong learning and Learn. is a great place to learn from experts. I've learned a lot and recommend it to all my friends and familys.”",
    },
  ],
};
