import React from "react";
import Pagination from "./Pagination";
import SingleBlog from "./SingleBlog";

const Blog = ({ data }) => {
  return (
    <>
      <section id="blog" className="ud-pb-20">
        <div className="ud-container">
          <div className="ud--mx-4 ud-flex ud-flex-wrap">
            {data.map((singleBlog) => (
              <SingleBlog key={singleBlog.id} blog={singleBlog} />
            ))}
          </div>

          <Pagination />
        </div>
      </section>
    </>
  );
};

export default Blog;
