export const signupData = {
  title: "Create your account",
  description: "  It's totally free and super easy",
  input: [
    {
      id: "1",
      label: "Full Name",
      placeholder: "Enter your Full Name",
      name: "name",
      type: "text",
    },
    {
      id: "2",
      label: "Your Email",
      placeholder: "Enter your Email",
      name: "email",
      type: "text",
    },
    {
      id: "3",
      label: "Your Password",
      placeholder: "Enter your Password",
      name: "password",
      type: "password",
    },
  ],
  button: "Sign up",
};
