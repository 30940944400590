/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const Download = ({ data }) => {
  const {
    img,
    img2,
    subTitle,
    title,
    description,
    appleIcon,
    appStore,
    playIcon,
    playStore,
  } = data;
  return (
    <>
      <section id="download" className="ud-py-24">
        <div className="ud-container">
          <div className="ud--mx-4 ud-flex ud-flex-wrap ud-items-center">
            <div className="ud-w-full ud-px-4 lg:ud-w-1/2">
              <div
                className="wow fadeInUp ud-mb-12 ud-max-w-[500px] lg:ud-mb-0"
                data-wow-delay="0s"
              >
                <span className="ud-mb-3 ud-text-lg ud-font-bold ud-uppercase ud-text-primary sm:ud-text-xl">
                  {subTitle}
                </span>
                <h2 className="ud-mb-3 ud-text-3xl ud-font-bold ud-leading-tight ud-text-black dark:ud-text-white md:ud-text-[45px]">
                  {title}
                </h2>
                <p className="ud-mb-10 ud-text-lg ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                  {description}
                </p>

                <div className="ud--mx-3 ud-flex ud-items-center">
                  <div className="ud-px-3">
                    <a
                      href=""
                      className="ud-flex ud-items-center ud-justify-center ud-rounded-full ud-border ud-border-[#2D2947] ud-bg-[#2D2947] ud-p-[10px] ud-pr-5 ud-text-base ud-font-semibold ud-text-white hover:ud-bg-opacity-90 dark:hover:ud-bg-opacity-90"
                    >
                      <span className="ud-mr-3 ud-flex ud-h-10 ud-w-10 ud-items-center ud-justify-center ud-rounded-full ud-bg-primary ud-text-white">
                        {appleIcon}
                      </span>
                      {appStore}
                    </a>
                  </div>
                  <div className="ud-px-3">
                    <a
                      href=""
                      className="ud-flex ud-items-center ud-justify-center ud-rounded-full ud-border ud-border-[#2D2947] ud-bg-transparent ud-p-[10px] ud-pr-5 ud-text-base ud-font-semibold ud-text-body-color-2 hover:ud-bg-[#2D294710] dark:ud-text-white dark:hover:ud-bg-[#2D2947]"
                    >
                      <span className="ud-mr-3 ud-flex ud-h-10 ud-w-10 ud-items-center ud-justify-center ud-rounded-full ud-bg-dark ud-text-white dark:ud-bg-white dark:ud-text-dark">
                        {playIcon}
                      </span>
                      {playStore}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="ud-w-full ud-px-4 lg:ud-w-1/2">
              <div
                className="wow fadeInUp ud-relative ud-text-center"
                data-wow-delay="0s"
              >
                <img
                  src={img}
                  alt="app image"
                  className="ud-mx-auto ud-hidden dark:ud-block ud-text-center"
                />
                <img
                  src={img2}
                  alt="app image"
                  className="ud-mx-auto dark:ud-hidden"
                />

                <span
                  className="ud-absolute ud-right-0 ud-bottom-0 ud-w-[320px] ud-h-[320px] ud-rounded-full ud--z-10"
                  style={{
                    background: `linear-gradient(
                    180deg,
                    rgba(55, 109, 249, 0) 0%,
                    rgba(255, 96, 166, 0.32) 100%
                  )`,
                    filter: `blur(100px)`,
                  }}
                ></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Download;
