/* eslint-disable react/jsx-no-target-blank */
import React from "react";
const Brand = ({ data }) => {
  const { title, brandLogo } = data;

  return (
    <>
      <section>
        <div className="ud-container">
          <div
            className="wow fadeInUp ud-border-y ud-border-[#F3F4F4] ud-pt-10 dark:ud-border-[#2D2C4A]"
            data-wow-delay="0s"
          >
            <h2 className="ud-mb-10 ud-text-center ud-text-lg ud-font-bold ud-text-black dark:ud-text-white sm:ud-text-2xl">
              {title}
            </h2>

            <div className="ud--mx-4 ud-flex ud-flex-wrap ud-items-center ud-justify-center">
              {brandLogo.map((items) => (
                <div key={items.id} className="ud-px-4">
                  <a
                    href={items.to}
                    target="_blank"
                    className="ud-mb-10 ud-flex ud-max-w-[170px] ud-justify-center ud-opacity-70 ud-grayscale hover:ud-opacity-100 hover:ud-grayscale-0 dark:hover:ud-opacity-100"
                  >
                    <img
                      src={items.darkImage}
                      alt="uideck"
                      className="ud-mx-auto ud-hidden ud-h-10 ud-text-center dark:ud-block"
                    />
                    <img
                      src={items.lightImage}
                      alt="uideck"
                      className="ud-mx-auto ud-h-10 ud-text-center dark:ud-hidden"
                    />
                  </a>
                </div>
              ))}
              {/*  */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Brand;
