import React from "react";
import image from "../../../images/sale-token/payment-method.svg";

const Token = ({ data }) => {
  const {
    subTitle,
    title,
    description,
    infoToken,
    days,
    dayText,
    hours,
    hourText,
    mins,
    minsText,
    sec,
    secText,
    button,
  } = data;
  return (
    <>
      <section className="ud-pb-[120px]">
        <div className="ud-container">
          <div
            className="wow fadeInUp ud-mx-auto ud-mb-16 ud-max-w-[590px] ud-text-center md:ud-mb-20"
            data-wow-delay="0s"
          >
            <span className="ud-mb-3 ud-text-lg ud-font-bold ud-uppercase ud-text-primary sm:ud-text-xl">
              C{subTitle}
            </span>
            <h2 className="ud-mb-3 ud-text-3xl ud-font-bold ud-leading-tight ud-text-black dark:ud-text-white md:ud-text-[45px]">
              {title}
            </h2>
            <p className="ud-text-lg ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
              {description}
            </p>
          </div>

          <div className="ud--mx-4 ud-flex ud-flex-wrap ud-items-center">
            <div className="ud-w-full ud-px-4 lg:ud-w-5/12">
              <div
                className="wow fadeInUp ud-mb-14 lg:ud-mb-0"
                data-wow-delay="0s"
              >
                <h3 className="ud-mb-12 ud-text-2xl ud-font-bold ud-text-black dark:ud-text-white">
                  Information About Tokens
                </h3>
                <div className="ud-space-y-3">
                  {infoToken.map((item) => (
                    <p
                      key={item.id}
                      className="ud-justify-between ud-text-base ud-font-semibold ud-text-body-color-2 dark:ud-text-body-color sm:ud-flex"
                    >
                      <span className="ud-block sm:ud-inline-block">
                        {item.tokenName}
                      </span>
                      <span className="ud-block sm:ud-inline-block">
                        {item.token}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            </div>

            <div className="ud-w-full ud-px-4 lg:ud-w-7/12">
              <div
                className="wow fadeInUp ud-rounded-lg ud-bg-dark ud-py-12 ud-px-8 sm:ud-py-16 sm:ud-px-14"
                data-wow-delay="0s"
              >
                <div className="ud-mb-24">
                  <div className="ud-flex ud-items-start ud-justify-center">
                    <div className="ud-text-center">
                      <span className="ud-mb-2 ud-block ud-text-2xl ud-font-bold ud-text-white sm:ud-text-3xl md:ud-text-[45px]">
                        {days}
                      </span>
                      <span className="ud-text-xs ud-font-medium ud-uppercase ud-text-white sm:ud-text-sm md:ud-text-lg">
                        {dayText}
                      </span>
                    </div>
                    <span className="ud-px-2 ud-text-2xl ud-font-bold ud-text-white sm:ud-px-4 sm:ud-text-3xl md:ud-text-[45px]">
                      :
                    </span>
                    <div className="ud-text-center">
                      <span className="ud-mb-2 ud-block ud-text-2xl ud-font-bold ud-text-white sm:ud-text-3xl md:ud-text-[45px]">
                        {hours}
                      </span>
                      <span className="ud-text-xs ud-font-medium ud-uppercase ud-text-white sm:ud-text-sm md:ud-text-lg">
                        {hourText}
                      </span>
                    </div>
                    <span className="ud-px-2 ud-text-2xl ud-font-bold ud-text-white sm:ud-px-4 sm:ud-text-3xl md:ud-text-[45px]">
                      :
                    </span>
                    <div className="ud-text-center">
                      <span className="ud-mb-2 ud-block ud-text-2xl ud-font-bold ud-text-white sm:ud-text-3xl md:ud-text-[45px]">
                        {mins}
                      </span>
                      <span className="ud-text-xs ud-font-medium ud-uppercase ud-text-white sm:ud-text-sm md:ud-text-lg">
                        {minsText}
                      </span>
                    </div>
                    <span className="ud-px-2 ud-text-2xl ud-font-bold ud-text-white sm:ud-px-4 sm:ud-text-3xl md:ud-text-[45px]">
                      :
                    </span>
                    <div className="ud-text-center">
                      <span className="ud-mb-2 ud-block ud-text-2xl ud-font-bold ud-text-white sm:ud-text-3xl md:ud-text-[45px]">
                        {sec}
                      </span>
                      <span className="ud-text-xs ud-font-medium ud-uppercase ud-text-white sm:ud-text-sm md:ud-text-lg">
                        {secText}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="ud-mb-8">
                  <div className="ud-relative ud-z-20">
                    <div className="ud-relative ud-h-8 ud-w-full ud-rounded-full ud-bg-[#515A95]">
                      <div className="ud-absolute ud-left-0 ud-bottom-0 ud-h-full ud-w-[75%] ud-rounded-full ud-bg-primary"></div>

                      <div className="ud-absolute ud-bottom-0 ud-left-0 ud-flex ud-w-full ud-justify-around">
                        <div className="ud-group ud-relative ud-flex ud-w-1/3 ud-justify-center">
                          <div className="ud-h-9 ud-w-[2px] ud-bg-[#384280]"></div>
                          <div className="ud-absolute ud-top-[-140%] ud-left-1/2 ud-z-20 ud--translate-x-1/2 ud-whitespace-nowrap ud-rounded-full ud-bg-[#384280] ud-px-5 ud-py-2 ud-text-sm ud-font-semibold ud-text-white ud-opacity-0 group-hover:ud-opacity-100">
                            <span className="ud-absolute ud--bottom-1 ud-left-1/2 ud-h-3 ud-w-3 ud--translate-x-1/2 ud-rotate-45 ud-bg-[#384280]"></span>
                            <span>PRE-SALE</span>
                          </div>
                        </div>
                        <div className="ud-group ud-relative ud-flex ud-w-1/3 ud-justify-center">
                          <div className="ud-h-9 ud-w-[2px] ud-bg-[#384280]"></div>
                          <div className="ud-absolute ud-top-[-140%] ud-left-1/2 ud-z-20 ud--translate-x-1/2 ud-whitespace-nowrap ud-rounded-full ud-bg-[#384280] ud-px-5 ud-py-2 ud-text-sm ud-font-semibold ud-text-white ud-opacity-0 group-hover:ud-opacity-100">
                            <span className="ud-absolute ud--bottom-1 ud-left-1/2 ud-h-3 ud-w-3 ud--translate-x-1/2 ud-rotate-45 ud-bg-[#384280]"></span>
                            <span>SOFT CAP</span>
                          </div>
                        </div>
                        <div className="ud-group ud-relative ud-flex ud-w-1/3 ud-justify-center">
                          <div className="ud-h-9 ud-w-[2px] ud-bg-[#384280]"></div>
                          <div className="ud-absolute ud-top-[-140%] ud-left-1/2 ud-z-20 ud--translate-x-1/2 ud-whitespace-nowrap ud-rounded-full ud-bg-[#384280] ud-px-5 ud-py-2 ud-text-sm ud-font-semibold ud-text-white ud-opacity-0 group-hover:ud-opacity-100">
                            <span className="ud-absolute ud--bottom-1 ud-left-1/2 ud-h-3 ud-w-3 ud--translate-x-1/2 ud-rotate-45 ud-bg-[#384280]"></span>
                            <span>BONUS</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ud-mb-10 ud-text-center">
                  <button className="ud-rounded-full ud-bg-primary ud-py-3 ud-px-8 ud-text-base ud-font-semibold ud-text-white hover:ud-bg-opacity-90">
                    {button}
                  </button>
                </div>
                <div className="ud-text-center">
                  <img
                    src={image}
                    alt="payment-method"
                    className="ud-mx-auto dark:ud-grayscale-0"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Token;
