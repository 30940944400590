import React from "react";
import Address from "./Address";
import Input from "./Input";

const Contact = ({ data }) => {
  const {
    subTitle,
    title,
    address,
    formTitle,
    input,
    textAreaTitle,
    textAreaPlaceholder,
    button,
  } = data;
  return (
    <>
      <section id="contact" className={`ud-pb-[120px] dark:ud-bg-black`}>
        <div className="ud-container">
          <div className="ud--mx-4 ud-flex ud-flex-wrap ud-items-center">
            <div className="ud-w-full ud-px-4 lg:ud-w-7/12">
              <div className="ud-mb-16 ud-max-w-[350px]">
                <span className="ud-mb-3 ud-text-lg ud-font-bold ud-uppercase ud-text-primary sm:ud-text-xl">
                  {subTitle}
                </span>
                <h2 className="ud-mb-3 ud-text-3xl ud-font-bold ud-leading-tight ud-text-black dark:ud-text-white md:ud-text-[45px]">
                  {title}
                </h2>
              </div>

              <div className="ud--mx-4 ud-flex ud-flex-wrap">
                {address.map((item) => (
                  <Address item={item} key={item.id} />
                ))}
              </div>
            </div>

            <div className="ud-w-full ud-px-4 lg:ud-w-5/12">
              <div className="sm:ud-14 ud-rounded-md ud-bg-white ud-py-12 ud-px-8 dark:ud-bg-dark">
                <h3 className="ud-mb-8 ud-text-2xl ud-font-bold ud-text-dark dark:ud-text-white sm:ud-text-[34px] lg:ud-text-2xl xl:ud-text-[34px]">
                  {formTitle}
                </h3>

                <form>
                  <div className="ud-mb-5">
                    {input.map((input) => (
                      <Input key={input.id} input={input} />
                    ))}
                  </div>

                  <div className="ud-mb-5">
                    <label
                      htmlFor="message"
                      className="ud-mb-2 ud-block ud-text-sm ud-font-medium ud-text-dark dark:ud-text-white"
                    >
                      {textAreaTitle}
                    </label>

                    <textarea
                      rows="6"
                      id="message"
                      placeholder={textAreaPlaceholder}
                      className="ud-w-full ud-rounded-md ud-border ud-border-[#E9E9E9] ud-border-opacity-50 ud-py-3 ud-px-5 ud-text-base ud-font-medium ud-text-body-color ud-outline-none focus:ud-border-primary dark:ud-border-opacity-20 dark:ud-bg-white dark:ud-bg-opacity-5"
                    ></textarea>
                  </div>
                  <div>
                    <button className="ud-w-full ud-rounded-full ud-bg-primary ud-p-3 ud-text-center ud-text-base ud-font-semibold ud-text-white hover:ud-bg-opacity-90 dark:ud-bg-white dark:ud-text-black dark:hover:ud-bg-opacity-90">
                      {button}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
