import AyroUi1 from "../../../../images/brands/ayroui.svg";
import AyroUi2 from "../../../../images/brands/AyroUI-white.svg";
import lineIcons1 from "../../../../images/brands/lineicons.svg";
import lineIcons2 from "../../../../images/brands/LineIcons-white.svg";
import PlainAdmin1 from "../../../../images/brands/plainadmin.svg";
import PlainAdmin2 from "../../../../images/brands/PlainAdmin-white.svg";
import uideck1 from "../../../../images/brands/uideck.svg";
import uideck2 from "../../../../images/brands/uideck-white.svg";
import tailgrids1 from "../../../../images/brands/tailgrids.svg";
import tailgrids2 from "../../../../images/brands/TailGrids-white.svg";

export const brandData = {
  title: "Join the 20,000+ companies using the our platform",
  brandLogo: [
    {
      id: "1",
      to: "https://uideck.com",
      lightImage: uideck1,
      darkImage: uideck2,
    },
    {
      id: "2",
      to: "https://tailgrids.com",
      lightImage: tailgrids1,
      darkImage: tailgrids2,
    },
    {
      id: "3",
      to: "https://ayroui.com",
      lightImage: AyroUi1,
      darkImage: AyroUi2,
    },
    {
      id: "4",
      to: "https://lineicons.com",
      lightImage: lineIcons1,
      darkImage: lineIcons2,
    },
    {
      id: "5",
      to: "https://plainadmin.com",
      lightImage: PlainAdmin1,
      darkImage: PlainAdmin2,
    },
  ],
};
