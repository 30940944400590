import React from "react";
import { Link } from "react-router-dom";
import shape1 from "../../../images/shapes/hero-shape-1.svg";
import shape2 from "../../../images/shapes/hero-shape-2.svg";

const SigninSection = ({ data }) => {
  const { title, description, input, button } = data;
  return (
    <>
      <section className="ud-relative ud-z-10 ud-pt-[180px]">
        <div className="ud-container">
          <div className="ud-mx-[-16px] ud-flex ud-flex-wrap">
            <div className="ud-w-full ud-px-4">
              <div
                className="wow fadeInUp ud-mx-auto ud-max-w-[500px] ud-rounded-md ud-border ud-border-[#f5f5f5] ud-bg-white ud-p-12 dark:ud-border-0 dark:ud-bg-dark sm:ud-p-[60px]"
                data-wow-delay="0s"
              >
                <h3 className="ud-mb-3 ud-text-center ud-text-2xl ud-font-bold ud-text-black dark:ud-text-white sm:ud-text-3xl">
                  {title}
                </h3>
                <p className="ud-mb-11 ud-text-center ud-text-base ud-font-medium ud-text-body-color">
                  {description}
                </p>
                <button className="ud-shadow-one dark:ud-shadow-signUp ud-mb-6 ud-flex ud-w-full ud-items-center ud-justify-center ud-rounded-full ud-border ud-border-body-color ud-border-opacity-50 ud-bg-white ud-p-3 ud-text-base ud-font-medium ud-text-body-color-2 hover:ud-text-primary dark:ud-border-opacity-30 dark:ud-bg-[#1F2656] dark:ud-text-body-color dark:hover:ud-text-white">
                  <span className="ud-mr-3">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_95:967)">
                        <path
                          d="M20.0001 10.2216C20.0122 9.53416 19.9397 8.84776 19.7844 8.17725H10.2042V11.8883H15.8277C15.7211 12.539 15.4814 13.1618 15.1229 13.7194C14.7644 14.2769 14.2946 14.7577 13.7416 15.1327L13.722 15.257L16.7512 17.5567L16.961 17.5772C18.8883 15.8328 19.9997 13.266 19.9997 10.2216"
                          fill="#4285F4"
                        />
                        <path
                          d="M10.2042 20.0001C12.9592 20.0001 15.2721 19.1111 16.9616 17.5778L13.7416 15.1332C12.88 15.7223 11.7235 16.1334 10.2042 16.1334C8.91385 16.126 7.65863 15.7206 6.61663 14.9747C5.57464 14.2287 4.79879 13.1802 4.39915 11.9778L4.27957 11.9878L1.12973 14.3766L1.08856 14.4888C1.93689 16.1457 3.23879 17.5387 4.84869 18.512C6.45859 19.4852 8.31301 20.0005 10.2046 20.0001"
                          fill="#34A853"
                        />
                        <path
                          d="M4.39911 11.9777C4.17592 11.3411 4.06075 10.673 4.05819 9.99996C4.0623 9.32799 4.17322 8.66075 4.38696 8.02225L4.38127 7.88968L1.19282 5.4624L1.08852 5.51101C0.372885 6.90343 0.00012207 8.4408 0.00012207 9.99987C0.00012207 11.5589 0.372885 13.0963 1.08852 14.4887L4.39911 11.9777Z"
                          fill="#FBBC05"
                        />
                        <path
                          d="M10.2042 3.86663C11.6663 3.84438 13.0804 4.37803 14.1498 5.35558L17.0296 2.59996C15.1826 0.901848 12.7366 -0.0298855 10.2042 -3.6784e-05C8.3126 -0.000477834 6.45819 0.514732 4.8483 1.48798C3.2384 2.46124 1.93649 3.85416 1.08813 5.51101L4.38775 8.02225C4.79132 6.82005 5.56974 5.77231 6.61327 5.02675C7.6568 4.28118 8.91279 3.87541 10.2042 3.86663Z"
                          fill="#EB4335"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_95:967">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  Sign in with Google
                </button>
                <div className="ud-mb-8 ud-flex ud-items-center ud-justify-center">
                  <span className="ud-hidden ud-h-[1px] ud-w-full ud-max-w-[70px] ud-bg-body-color ud-bg-opacity-50 sm:ud-block"></span>
                  <p className="ud-w-full ud-px-5 ud-text-center ud-text-base ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                    Or, sign in with your email
                  </p>
                  <span className="ud-hidden ud-h-[1px] ud-w-full ud-max-w-[70px] ud-bg-body-color ud-bg-opacity-50 sm:ud-block"></span>
                </div>
                <form>
                  {input.map((i) => (
                    <div key={i.id} className="ud-mb-8">
                      <label
                        htmlFor={i.name}
                        className="ud-mb-3 ud-block ud-text-sm ud-font-medium ud-text-dark dark:ud-text-white"
                      >
                        {i.label}
                      </label>
                      <input
                        type={i.type}
                        name={i.name}
                        placeholder={i.placeholder}
                        className="ud-shadow-one dark:ud-shadow-signUp ud-w-full ud-rounded-full ud-border ud-border-body-color ud-border-opacity-50 ud-py-3 ud-px-6 ud-text-base ud-text-body-color ud-placeholder-body-color ud-outline-none focus:ud-border-primary focus-visible:ud-shadow-none dark:ud-border-opacity-30 dark:ud-bg-[#1F2656]"
                      />
                    </div>
                  ))}

                  <div className="ud-mb-8 ud-flex ud-items-center ud-justify-between">
                    <div>
                      <label
                        htmlFor="checkboxLabel"
                        className="ud-flex ud-cursor-pointer ud-select-none ud-items-center ud-text-sm ud-font-medium ud-text-body-color-2 dark:ud-text-body-color"
                      >
                        <div className="ud-relative">
                          <input
                            type="checkbox"
                            id="checkboxLabel"
                            className="ud-sr-only"
                          />
                          <div className="box ud-mr-4 ud-flex ud-h-5 ud-w-5 ud-items-center ud-justify-center ud-rounded ud-border ud-border-body-color ud-border-opacity-30 dark:ud-border-white dark:ud-border-opacity-10">
                            <span className="ud-opacity-0">
                              <svg
                                width="11"
                                height="8"
                                viewBox="0 0 11 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M10.0915 0.951972L10.0867 0.946075L10.0813 0.940568C9.90076 0.753564 9.61034 0.753146 9.42927 0.939309L4.16201 6.22962L1.58507 3.63469C1.40401 3.44841 1.11351 3.44879 0.932892 3.63584C0.755703 3.81933 0.755703 4.10875 0.932892 4.29224L0.932878 4.29225L0.934851 4.29424L3.58046 6.95832C3.73676 7.11955 3.94983 7.2 4.1473 7.2C4.36196 7.2 4.55963 7.11773 4.71406 6.9584L10.0468 1.60234C10.2436 1.4199 10.2421 1.1339 10.0915 0.951972ZM4.2327 6.30081L4.2317 6.2998C4.23206 6.30015 4.23237 6.30049 4.23269 6.30082L4.2327 6.30081Z"
                                  fill="#3056D3"
                                  stroke="#3056D3"
                                  strokeWidth="0.4"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                        Keep me signed in
                      </label>
                    </div>
                    <div>
                      <Link
                        to="#"
                        className="ud-text-sm ud-font-medium ud-text-primary hover:ud-underline"
                      >
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                  <div className="ud-mb-6">
                    <button className="hover:ud-shadow-signUp ud-flex ud-w-full ud-items-center ud-justify-center ud-rounded-full ud-bg-primary ud-py-4 ud-px-9 ud-text-base ud-font-medium ud-text-white ud-transition ud-duration-300 ud-ease-in-out hover:ud-bg-opacity-80">
                      {button}
                    </button>
                  </div>
                </form>
                <p className="ud-text-center ud-text-base ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                  Don't you have an account?
                  <Link
                    to="/signup"
                    className="ud-text-primary hover:ud-underline"
                  >
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundImage: `linear-gradient(180deg,#3e7dff 0%,rgba(62, 125, 255, 0) 100%)`,
          }}
          className="ud-absolute ud-top-0 ud-left-0 ud--z-10 ud-h-full ud-w-full ud-opacity-20"
        ></div>
        <img
          src={shape1}
          alt=""
          className="ud-absolute ud-left-0 ud-top-0 ud--z-10"
        />
        <img
          src={shape2}
          alt=""
          className="ud-absolute ud-right-0 ud-top-0 ud--z-10"
        />
      </section>
    </>
  );
};

export default SigninSection;
