import React from "react";
import image1 from "../../../../images/shapes/token-sale-shape.svg";
import Chart from "react-apexcharts";

const TokenSale = ({ data }) => {
  const { subtitle, title, description, offerList } = data;

  const chart = {
    options: {
      colors: ["#2347B9", "#3363FF", "#8BA6FF", "#8696CA"],
      legend: {
        show: false,
      },
      stroke: {
        show: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
    series: [73, 55, 38, 20],
  };

  return (
    <>
      <section className="ud-relative ud-z-10">
        <div className="ud-container">
          <div className="ud-rounded-lg ud-bg-light-bg ud-py-12 ud-px-8 dark:ud-bg-[#14102C] sm:ud-py-16 sm:ud-px-14 lg:ud-px-8 xl:ud-px-14">
            <div className="ud--mx-4 ud-flex ud-flex-wrap ud-items-center">
              <div className="ud-w-full ud-px-4 lg:ud-w-1/2">
                <div className="wow fadeInUp ud-mb-12 ud-flex ud-items-center ud-justify-center lg:ud-mb-0">
                  <div>
                    <Chart
                      options={chart.options}
                      series={chart.series}
                      type="donut"
                      width="400"
                    />
                  </div>
                </div>
              </div>
              <div className="ud-w-full ud-px-4 lg:ud-w-1/2">
                <div className="wow fadeInUp ud-mb-9" data-wow-delay="0s">
                  <span className="ud-mb-3 ud-text-lg ud-font-bold ud-uppercase ud-text-primary sm:ud-text-xl">
                    {subtitle}
                  </span>
                  <h2 className="ud-mb-3 ud-text-3xl ud-font-bold ud-leading-tight ud-text-black dark:ud-text-white md:ud-text-[45px]">
                    {title}
                  </h2>
                  <p className="ud-text-lg ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                    {description}
                  </p>
                </div>
                <div className="wow fadeInUp ud-space-y-4" data-wow-delay="0s">
                  {offerList.map((list) => (
                    <p key={list.id} className="ud-flex">
                      <span
                        className={`ud-mr-4 ud-h-6 ud-w-6 ud-rounded-full ${list.circleColor}`}
                      ></span>
                      <span className="ud-text-lg ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                        {list.listText}
                      </span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ud-absolute ud-right-0 ud--top-32 ud--z-10">
          <img src={image1} alt="shape" />
        </div>
      </section>
    </>
  );
};

export default TokenSale;
