import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import darkLogo from "../../../images/logo/logo-white.svg";
import lightLogo from "../../../images/logo/logo.svg";

import { FaBars } from "react-icons/fa";
import { TiTimes } from "react-icons/ti";
import ScrollLink from "./ScrollLink";
import Toggler from "./Toggler";

const Navbar = ({ handleThemeSwitch, theme }) => {
  const [open, setOpen] = useState(false);
  const [subMenu, setSubmenu] = useState(false);
  // const [toggle, setToggle] = useState(false);

  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <header
        className={`ud-top-0 ud-z-50 ud-flex ud-w-full ud-items-center ud-fixed ${
          scroll
            ? `ud-bg-white dark:ud-bg-dark ud-bg-opacity-80 dark:ud-bg-opacity-80 ud-shadow-sticky ud-backdrop-blur-sm `
            : "ud-bg-transparent dark:ud-bg-transparent"
        }`}
      >
        <div className="ud-container">
          <div className="ud-relative ud--mx-4 ud-flex ud-items-center ud-justify-between">
            {/* Logo Area Start */}
            <div className="ud-w-60 ud-max-w-full ud-px-4">
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                to="/"
                className={`ud-block ud-w-full ud-py-6 lg:ud-py-5 ${
                  scroll && "!ud-py-4 lg:!ud-py-2"
                } `}
              >
                <img
                  src={lightLogo}
                  alt="logo"
                  className="ud-w-full dark:ud-hidden"
                />
                <img
                  src={darkLogo}
                  alt="logo"
                  className="ud-hidden ud-w-full dark:ud-block"
                />
              </Link>
            </div>
            {/* Logo Area End */}

            <div className="ud-flex ud-w-full ud-items-center ud-justify-end ud-px-4">
              <div>
                {/* Hambagar Menu Icon Start */}
                <div
                  onClick={() => setOpen(!open)}
                  className="ud-absolute ud-right-4 ud-top-1/2 ud-block ud--translate-y-1/2 ud-rounded-lg ud-px-3 ud-py-[6px] ud-ring-primary focus:ud-ring-2 lg:ud-hidden"
                >
                  {open ? (
                    <TiTimes color="white" size={30} />
                  ) : (
                    <FaBars color="white" size={30} />
                  )}
                </div>

                {/* Hambagar Menu Icon End */}
                <nav
                  // :className="!navbarOpen && 'ud-hidden' "
                  // id="navbarCollapse"
                  className={`ud-absolute ud-right-4 ud-top-full ud-w-full ud-max-w-[250px] ud-rounded-lg ud-bg-white ud-py-4 ud-px-6 ud-shadow dark:ud-bg-black lg:ud-static lg:ud-block lg:ud-w-full lg:ud-max-w-full lg:ud-bg-transparent lg:ud-py-0 lg:ud-shadow-none dark:lg:ud-bg-transparent ${
                    open === false && `ud-hidden`
                  }`}
                >
                  <ul className="ud-block lg:ud-flex">
                    <li
                      onClick={() => {
                        window.scrollTo({
                          top: 0,
                          left: 0,
                          behavior: "smooth",
                        });
                      }}
                      className="ud-text-body-color-2 dark:ud-text-body-color"
                    >
                      <Link
                        to="/"
                        className="scroll-menu ud-flex ud-py-2 ud-text-lg ud-font-semibold ud-text-body-color-2 hover:ud-text-primary dark:ud-text-body-color dark:hover:ud-text-white lg:ud-ml-7 lg:ud-inline-flex lg:ud-py-5 xl:ud-ml-10 2xl:ud-ml-12"
                      >
                        Home
                      </Link>
                    </li>

                    <ScrollLink navbarData={navbarData} />

                    <li className="ud-group submenu-item ud-relative">
                      <Link
                        to="#"
                        onClick={() => setSubmenu(!subMenu)}
                        className="ud-relative ud-flex ud-py-2 ud-text-lg ud-font-semibold ud-text-body-color-2 after:ud-absolute after:ud-right-1 after:ud-top-1/2 after:ud-mt-[-2px] after:ud-h-2 after:ud-w-2 after:ud-translate-y-[-50%] after:ud-rotate-45 after:ud-border-b-2 after:ud-border-r-2 after:ud-border-current group-hover:ud-text-primary dark:ud-text-body-color dark:group-hover:ud-text-white lg:ud-ml-7 lg:ud-inline-flex lg:ud-py-5 lg:ud-pl-0 lg:ud-pr-4 lg:after:ud-right-0 xl:ud-ml-10 2xl:ud-ml-12"
                      >
                        Pages
                      </Link>
                      <div
                        // :className="dropdownOpen ? 'ud-block' : 'ud-hidden lg:ud-block ' "
                        className={`submenu ud-relative ud-top-full ud-left-0 ud-rounded-lg ud-bg-white ud-transition-[top] ud-duration-300 group-hover:ud-opacity-100 dark:ud-bg-black lg:ud-invisible lg:ud-absolute lg:ud-top-[115%] lg:ud-w-[250px] lg:ud-p-4 lg:ud-opacity-0 lg:ud-shadow-lg lg:group-hover:ud-visible lg:group-hover:ud-top-full ${
                          subMenu ? "ud-block" : "ud-hidden lg:ud-block "
                        } `}
                      >
                        {subMenus.map((subNav) => (
                          <Link
                            onClick={() => {
                              window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                              });
                            }}
                            key={subNav.id}
                            to={subNav.to}
                            className="ud-block ud-rounded ud-py-[10px] ud-text-sm ud-text-black hover:ud-text-primary dark:ud-text-white dark:hover:ud-text-primary lg:ud-px-4"
                          >
                            {subNav.title}
                          </Link>
                        ))}
                      </div>
                    </li>
                  </ul>
                </nav>
              </div>

              <div className="ud-flex ud-justify-end ud-pr-16 lg:ud-pr-0 xl:ud-pl-12 2xl:ud-pl-20">
                {/* toggler Menu Icon Start */}
                <Toggler handleThemeSwitch={handleThemeSwitch} theme={theme} />
                {/* toggler Menu Icon End */}

                <div className="ud-hidden sm:ud-flex">
                  <Link
                    onClick={() => {
                      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    }}
                    to="/signin"
                    className="ud-flex ud-items-center ud-justify-center ud-rounded-full ud-border ud-border-body-color-2 ud-py-[9px] ud-px-8 ud-text-base ud-font-semibold ud-text-body-color-2 ud-transition-all hover:ud-border-primary hover:ud-bg-primary hover:ud-text-white dark:ud-border-white dark:ud-text-white dark:hover:ud-bg-white dark:hover:ud-text-primary lg:px-4 xl:px-8"
                  >
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Navbar;

const navbarData = [
  {
    id: 2,
    title: "Features",
    to: "features",
  },
  {
    id: 3,
    title: "Roadmap",
    to: "roadmap",
  },

  {
    id: 5,
    title: "Support",
    to: "contact",
  },
];
const subMenus = [
  {
    id: 1,
    title: "Blog Grid Page",
    to: "/blog-grid",
  },
  {
    id: 2,
    title: "Blog Details Page",
    to: "/blog-details",
  },
  {
    id: 3,
    title: "Token Sale Page",
    to: "/token-sale",
  },

  {
    id: 4,
    title: "Support Page",
    to: "/support",
  },
  {
    id: 5,
    title: "Signin Page",
    to: "/signin",
  },
  {
    id: 6,
    title: "Signup Page",
    to: "/signup",
  },
];
