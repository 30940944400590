export const signinData = {
  title: "Sign in to your account",
  description: "Login to your account for a faster checkout.",
  input: [
    {
      id: "1",
      label: "Your Email",
      placeholder: "Enter your Email",
      name: "email",
      type: "text",
    },
    {
      id: "2",
      label: "Your Password",
      placeholder: "Enter your Password",
      name: "password",
      type: "password",
    },
  ],
  button: "Sign in",
};
