export const tokenData = {
  subTitle: "CRYPTO FEATURE",
  title: "Token Sale",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condimentum vel.",

  infoToken: [
    {
      id: "1",
      tokenName: "Token Name",
      token: "ethereum",
    },
    {
      id: "2",
      tokenName: "Nominal Price",
      token: "1eth = 0.025USD",
    },
    {
      id: "3",
      tokenName: "Total Number of Token Produced",
      token: "7 BN smt",
    },
    {
      id: "4",
      tokenName: "Unsold Tokens",
      token: "Burn Smart Contract",
    },
    {
      id: "5",
      tokenName: "Type of Token",
      token: "ERC-20",
    },
    {
      id: "6",
      tokenName: "Minimal transaction amount",
      token: "1 ETH/ 1 BTC/ 1 LTC",
    },
  ],
  days: "615",
  dayText: "DAYS",
  hours: "0",
  hourText: "HOURS",
  mins: "45",
  minsText: "MINUTES",
  sec: "04",
  secText: "SECONDS",
  button: "Buy Token Now",
};
