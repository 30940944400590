/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const TeamCard = ({ card }) => {
  const { image, name, profession, social } = card;
  return (
    <>
      <div className="ud-w-full ud-px-4 sm:ud-w-1/2 lg:ud-w-1/4">
        <div
          className="ud-group wow fadeInUp ud-mb-10 ud-rounded-lg ud-border-b-4 ud-border-transparent ud-bg-light-bg ud-p-4 ud-text-center ud-transition-all hover:ud-border-primary dark:ud-bg-dark"
          data-wow-delay="0s"
        >
          <div className="ud-mb-6 ud-overflow-hidden ud-rounded">
            <img src={image} alt="team" className="ud-rounded- ud-w-full" />
          </div>
          <div>
            <h3 className="ud-mb-2 ud-text-lg ud-font-semibold ud-text-black dark:ud-text-white">
              {name}
            </h3>
            <p className="ud-mb-3 ud-text-sm ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
              {profession}
            </p>

            <div className="ud-mb-4 ud-flex ud-items-center ud-justify-center ud-space-x-4">
              {social.map((singleSocial) => (
                <a
                  key={singleSocial.id}
                  href={singleSocial.href}
                  className="ud-text-[#B6BFC9] hover:ud-text-primary dark:ud-text-white dark:hover:ud-text-primary"
                  name="social-link"
                  aria-label="social-link"
                >
                  {singleSocial.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCard;
