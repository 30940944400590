import React from "react";
import Breadcrumb from "../../share/Breadcrumb/Breadcrumb";
import Newsletter from "../../share/Newsletter/Newsletter";
import Token from "./Token";

import { tokenData } from "./tokenData";

const TokenSale = () => {
  document.title = "Token Sale - Crypto React";

  return (
    <>
      <Breadcrumb />
      <Token data={tokenData} />
      <Newsletter />
    </>
  );
};

export default TokenSale;
