import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const SingleBlog = ({ blog }) => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { blogId = "details" } = useParams();

  const {
    image,
    publisher,
    publisherIcon,
    data,
    calenderIcon,
    title,
    description,
  } = blog;
  return (
    <>
      <div className="ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/3">
        <div
          className="wow fadeInUp ud-mb-10 ud-overflow-hidden ud-rounded-lg ud-bg-light-bg dark:ud-bg-[#14102C]"
          data-wow-delay="0s"
        >
          <Link
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            to={"/blog-details/" + blogId}
            className="ud-block"
          >
            <img src={image} alt="blog" className="ud-w-full" />
          </Link>
          <div className="ud-p-7 lg:ud-px-6 xl:ud-p-8">
            <div className="ud-mb-2 ud-flex ud-items-center">
              <p className="ud-mr-3 ud-flex ud-items-center ud-text-body-color-2 dark:ud-text-body-color">
                <span className="ud-pr-2 ud-text-dark dark:ud-text-white">
                  {publisherIcon}
                </span>
                {publisher}
              </p>
              <p className="ud-flex ud-items-center ud-text-body-color-2 dark:ud-text-body-color">
                <span className="ud-pr-2 ud-text-dark dark:ud-text-white">
                  {calenderIcon}
                </span>
                {data}
              </p>
            </div>

            <h3>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                to={"/blog-details/" + blogId}
                className="ud-mb-4 ud-block ud-text-xl ud-font-bold ud-text-dark hover:ud-text-primary dark:ud-text-white dark:hover:ud-text-primary sm:ud-text-2xl lg:ud-text-lg xl:ud-text-2xl"
              >
                {title}
              </Link>
            </h3>
            <p className="ud-text-base ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
              {description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBlog;
