import React from "react";
import shape from "../../../../images/shapes/testimonial-shape.svg";

const TestimonialCard = ({ card }) => {
  const { image, name, profession, review } = card;

  return (
    <>
      <div className="ud-w-full ud-px-4 md:ud-w-1/2">
        <div
          className="wow fadeInUp ud-relative ud-z-10 ud-mb-10 ud-overflow-hidden ud-rounded-[10px] ud-bg-white ud-px-6 ud-py-8 dark:ud-bg-[#131B4D] sm:ud-p-10 md:ud-p-8 xl:ud-p-10"
          data-wow-delay="0s"
        >
          <div className="ud-absolute ud-right-0 ud-top-0 ud-z-[-1]">
            <img src={shape} alt="shape" />
          </div>
          <div className="ud-mb-8 ud-flex ud-items-center">
            <div className="ud-mr-5 ud-h-20 ud-w-full ud-max-w-[80px] ud-overflow-hidden ud-rounded">
              <img src={image} alt="shape" />
            </div>
            <div className="ud-w-full">
              <h3 className="ud-mb-1 ud-text-lg ud-font-semibold ud-text-dark dark:ud-text-white">
                {name}
              </h3>
              <p className="ud-text-sm ud-font-medium ud-text-body-color-2 dark:ud-text-white">
                {profession}
              </p>
            </div>
          </div>
          <div>
            <p className="ud-text-base ud-font-medium ud-leading-snug ud-text-body-color-2 dark:ud-text-body-color">
              {review}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;
