export const tokenData = {
  subtitle: "TOKEN",
  title: "Token Sale",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus condimentum tellus at lectus pulvinar, id auctor felis iaculis. In vestibulum neque sem, at dapibus justo facilisis in.",
  offerList: [
    {
      id: "1",
      circleColor: "ud-bg-[#3E7DFF]",
      listText: "73% Financial Overhead",
    },
    {
      id: "2",
      circleColor: "ud-bg-[#2347B9]",
      listText: "55% Bonus & found",
    },
    {
      id: "3",
      circleColor: "ud-bg-[#8BA6FF]",
      listText: "38% it infastrueture",
    },
    {
      id: "4",
      circleColor: "ud-bg-[#8696CA]",
      listText: "20.93% Gift Code Inventory",
    },
  ],
};
