import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import BlogDetails from "./components/pages/BlogDetails/BlogDetails";
import BlogGrid from "./components/pages/BlogGrid/BlogGrid";
import Error404 from "./components/pages/Error404/Error404";
import Home from "./components/pages/Home/Home";
import Signin from "./components/pages/Signin/Signin";
import Signup from "./components/pages/Signup/Signup";
import Support from "./components/pages/Support/Support";
import TokenSale from "./components/pages/TokenSale/TokenSale";
import Footer from "./components/share/Footer/Footer";
import Navbar from "./components/share/Navbar/Navbar";

const App = () => {
  const [theme, setTheme] = useState(null);

  useEffect(() => {
    if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
      setTheme("ud-dark");
    } else {
      setTheme("light");
    }
  }, []);

  const handleThemeSwitch = () => {
    setTheme(theme === "ud-dark" ? "light" : "ud-dark");
  };

  useEffect(() => {
    if (theme === "ud-dark") {
      document.documentElement.classList.add("ud-dark");
    } else {
      document.documentElement.classList.remove("ud-dark");
    }
  }, [theme]);

  return (
    <>
      <div className="ud-bg-white dark:ud-bg-black">
        <Navbar handleThemeSwitch={handleThemeSwitch} theme={theme} />

        <Routes>
          <Route index element={<Home />} />
          <Route path="/blog-grid" element={<BlogGrid />} />
          <Route path="/blog-details/:blogId" element={<BlogDetails />} />
          <Route path="/token-sale" element={<TokenSale />} />
          <Route path="/support" element={<Support />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="*" element={<Error404 />} />
        </Routes>

        <Footer />
      </div>
    </>
  );
};

export default App;
