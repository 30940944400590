export const newslatterData = {
  title: "Newsletter",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam vitae quam nec ante aliquet fringilla vel at erat.",
  placeholder: "Enter email address",
  button: "Submit",
  icon: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.67496 17.5L19.1666 10L1.67496 2.5L1.66663 8.33333L14.1666 10L1.66663 11.6667L1.67496 17.5Z"
        fill="white"
      />
    </svg>
  ),
};
