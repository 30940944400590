/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";

const BlogCard = ({ card }) => {
  const { image, publisher, date, title, description } = card;

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const { blogId = "details" } = useParams();

  return (
    <>
      <div className="ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/3">
        <div className="ud-mb-10 ud-overflow-hidden ud-rounded-lg ud-bg-light-bg dark:ud-bg-[#14102C]">
          <Link
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
            to={"/blog-details/" + blogId}
            className="ud-block"
          >
            <img src={image} alt="blog-image" className="ud-w-full" />
          </Link>
          <div className="ud-p-7 lg:ud-px-6 xl:ud-p-8">
            <div className="ud-mb-2 ud-flex ud-items-center">
              <p className="ud-mr-3 ud-flex ud-items-center ud-text-body-color-2 dark:ud-text-body-color">
                <span className="ud-pr-2 ud-text-dark dark:ud-text-white">
                  <svg
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    className="ud-fill-current"
                  >
                    <path d="M11.0001 3.66675C11.9725 3.66675 12.9052 4.05306 13.5928 4.74069C14.2804 5.42832 14.6667 6.36095 14.6667 7.33341C14.6667 8.30587 14.2804 9.2385 13.5928 9.92614C12.9052 10.6138 11.9725 11.0001 11.0001 11.0001C10.0276 11.0001 9.09499 10.6138 8.40736 9.92614C7.71972 9.2385 7.33341 8.30587 7.33341 7.33341C7.33341 6.36095 7.71972 5.42832 8.40736 4.74069C9.09499 4.05306 10.0276 3.66675 11.0001 3.66675ZM11.0001 12.8334C15.0517 12.8334 18.3334 14.4742 18.3334 16.5001V18.3334H3.66675V16.5001C3.66675 14.4742 6.94841 12.8334 11.0001 12.8334Z" />
                  </svg>
                </span>
                {publisher}
              </p>
              <p className="ud-flex ud-items-center ud-text-body-color-2 dark:ud-text-body-color">
                <span className="ud-pr-2 ud-text-dark dark:ud-text-white">
                  <svg
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    className="ud-fill-current"
                  >
                    <path d="M6.125 10.5H7.875V12.25H6.125V10.5ZM18.375 5.25V17.5C18.375 17.9641 18.1906 18.4092 17.8624 18.7374C17.5342 19.0656 17.0891 19.25 16.625 19.25H4.375C3.40375 19.25 2.625 18.4625 2.625 17.5V5.25C2.625 4.78587 2.80937 4.34075 3.13756 4.01256C3.46575 3.68437 3.91087 3.5 4.375 3.5H5.25V1.75H7V3.5H14V1.75H15.75V3.5H16.625C17.0891 3.5 17.5342 3.68437 17.8624 4.01256C18.1906 4.34075 18.375 4.78587 18.375 5.25ZM4.375 7H16.625V5.25H4.375V7ZM16.625 17.5V8.75H4.375V17.5H16.625ZM13.125 12.25V10.5H14.875V12.25H13.125ZM9.625 12.25V10.5H11.375V12.25H9.625ZM6.125 14H7.875V15.75H6.125V14ZM13.125 15.75V14H14.875V15.75H13.125ZM9.625 15.75V14H11.375V15.75H9.625Z" />
                  </svg>
                </span>
                {date}
              </p>
            </div>

            <h3>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                to={"/blog-details/" + blogId}
                className="ud-mb-4 ud-block ud-text-xl ud-font-bold ud-text-dark hover:ud-text-primary dark:ud-text-white dark:hover:ud-text-primary sm:ud-text-2xl lg:ud-text-lg xl:ud-text-2xl"
              >
                {title}
              </Link>
            </h3>
            <p className="ud-text-base ud-font-medium ud-leading-loose ud-text-body-color-2 dark:ud-text-body-color">
              {description}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogCard;
