import React from "react";

const Icon = ({ icon }) => {
  const { image, name } = icon;

  return (
    <>
      <div className="ud-group ud-relative ud-mt-4 ud-px-1 sm:ud-px-2">
        <span className="ud-mt-2 ud-flex ud-h-10 ud-w-10 ud-items-center ud-justify-center ud-rounded-full ud-bg-white">
          {image}
        </span>
        <div className="ud-absolute ud--top-full ud-left-1/2 ud-z-50 ud--translate-x-1/2 ud-whitespace-nowrap ud-rounded-full ud-bg-[#2D2C4A] ud-px-5 ud-py-2 ud-text-white ud-opacity-0 group-hover:ud-opacity-100">
          <span className="ud-absolute ud--bottom-1 ud-left-1/2 ud-h-3 ud-w-3 ud--translate-x-1/2 ud-rotate-45 ud-bg-[#2D2C4A]"></span>
          <span>{name}</span>
        </div>
      </div>
    </>
  );
};

export default Icon;
