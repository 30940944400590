import image1 from "../../../../images/blogs/image-01.jpg";
import image2 from "../../../../images/blogs/image-02.jpg";
import image3 from "../../../../images/blogs/image-03.jpg";

export const newsBlogsData = {
  subTitle: "LATEST NEWS",
  title: "Recent News & Blogs",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condimentum vel.",

  blogCard: [
    {
      id: "1",
      image: image1,
      publisher: "by admin",
      date: "Jan 25, 2025",
      title: "Expenses as material bre mate insisted building",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condime vel.",
    },
    {
      id: "2",
      image: image2,
      publisher: "by admin",
      date: "Jan 25, 2025",
      title: "Quis nostrud exercitati ullamc laboris nisi aliquip",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condime vel.",
    },
    {
      id: "3",
      image: image3,
      publisher: "by admin",
      date: "Jan 25, 2025",
      title: "Laboris nisi aliquip dium exiuliym commo cons.",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condime vel.",
    },
  ],
};
