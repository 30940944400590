import React from "react";

const Address = ({ item }) => {
  const { title, address, email1, email2, phone1, phone2 } = item;
  return (
    <>
      <div className="ud-w-full ud-px-4 sm:ud-w-1/2">
        <div className="ud-mb-11 ud-max-w-[250px]">
          <h3 className="ud-mb-4 ud-text-lg ud-font-semibold ud-text-dark dark:ud-text-white">
            {title}
          </h3>
          <p className="ud-text-base ud-font-medium ud-leading-loose ud-text-body-color-2 dark:ud-text-body-color">
            {address || email1 || phone1}
          </p>
          <p className="ud-text-base ud-font-medium ud-leading-loose ud-text-body-color-2 dark:ud-text-body-color">
            {email2 || email2 || phone2}
          </p>
        </div>
      </div>
    </>
  );
};

export default Address;
