import React from "react";
import { Link } from "react-router-dom";

const TimeLineCard = ({ card }) => {
  const { position, date, cardDescription, iconSvg, name, value } = card;
  return (
    <>
      {position === "left" && (
        <>
          <div className="ud-w-full ud-px-4 md:ud-w-1/2">
            <div className="ud-relative ud-z-10 ud-mb-10 ud-rounded-lg ud-bg-light-bg ud-py-8 ud-px-6 dark:ud-bg-dark md:ud-mr-3 md:ud-mb-0 md:ud-text-right lg:ud-mr-5">
              <span className="ud-absolute ud-top-1/2 ud-left-0 ud-hidden ud-h-4 ud-w-4 ud--translate-y-1/2 ud-rounded-full ud-border-4 ud-border-primary ud-bg-white dark:ud-border-body-color md:ud-left-auto md:ud--right-9 md:ud-block lg:ud--right-11"></span>
              <span className="ud-absolute ud--right-1 ud-top-1/2 ud-hidden ud-h-3 ud-w-3 ud--translate-y-1/2 ud-rotate-45 ud-bg-light-bg dark:ud-bg-dark md:ud-block"></span>
              <h3 className="ud-mb-3 ud-text-xl ud-font-bold ud-text-black dark:ud-text-white">
                {date}
              </h3>
              <p className="ud-mb-5 ud-text-base ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                {cardDescription}
              </p>

              <Link
                to=""
                className="ud-inline-flex ud-items-center ud-justify-center ud-rounded-full ud-bg-white ud-p-2 ud-pr-3"
              >
                <span className="ud-pr-1">{iconSvg}</span>
                <span className="ud-pr-2 ud-text-xs ud-font-semibold ud-text-body-color-2 dark:ud-text-body-color">
                  {name}
                </span>

                <span className="ud-text-[8px] ud-font-semibold ud-text-body-color-2 dark:ud-text-[#959595]">
                  {value}
                </span>
                <span>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.66675 5.00008L2.25425 5.58758L4.58341 3.26258V8.33341H5.41675V3.26258L7.74175 5.59175L8.33341 5.00008L5.00008 1.66675L1.66675 5.00008Z"
                      fill="#60D16B"
                    />
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </>
      )}
      {position === "right" && (
        <>
          <div className="ud-w-full ud-px-4 md:ud-w-1/2"></div>
          <div className="ud-w-full ud-px-4 md:ud-w-1/2"></div>
          <div className="ud-w-full ud-px-4 md:ud-w-1/2">
            <div className="ud-relative ud-z-10 ud-mb-10 ud-rounded-lg ud-bg-light-bg ud-py-8 ud-px-6 dark:ud-bg-dark md:ud-ml-3 md:ud-mb-0 lg:ud-ml-5">
              <span className="ud-absolute ud-top-1/2 ud-left-0 ud-hidden ud-h-4 ud-w-4 ud--translate-y-1/2 ud-rounded-full ud-border-4 ud-border-primary ud-bg-white dark:ud-border-body-color md:ud-right-auto md:ud--left-9 md:ud-block lg:ud--left-11"></span>
              <span className="ud-absolute ud--left-1 ud-top-1/2 ud-hidden ud-h-3 ud-w-3 ud--translate-y-1/2 ud-rotate-45 ud-bg-light-bg dark:ud-bg-dark md:ud-block"></span>
              <h3 className="ud-mb-3 ud-text-xl ud-font-bold ud-text-black dark:ud-text-white">
                {date}
              </h3>
              <p className="ud-mb-5 ud-text-base ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
                {cardDescription}
              </p>

              <Link
                to=""
                className="ud-inline-flex ud-items-center ud-justify-center ud-rounded-full ud-bg-white ud-p-2 ud-pr-3"
              >
                <span className="ud-pr-1">{iconSvg}</span>
                <span className="ud-pr-2 ud-text-xs ud-font-semibold ud-text-body-color-2 dark:ud-text-body-color">
                  {name}
                </span>

                <span className="ud-text-[8px] ud-font-semibold ud-text-body-color-2 dark:ud-text-[#959595]">
                  {value}
                </span>
                <span>
                  <svg
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.66675 5.00008L2.25425 5.58758L4.58341 3.26258V8.33341H5.41675V3.26258L7.74175 5.59175L8.33341 5.00008L5.00008 1.66675L1.66675 5.00008Z"
                      fill="#60D16B"
                    />
                  </svg>
                </span>
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default TimeLineCard;
