import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  document.title = "Not Found Any Page - Crypto React";

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <section className="ud-pt-[120px] lg:ud-pt-[240px]">
        <div className="ud-px-4 xl:ud-container">
          <div className=" ud-pb-20  lg:ud-pb-[130px]">
            <div className="ud-relative ud-mx-auto ud-max-w-[550px] ud-pt-6 ud-text-center md:ud-pt-8">
              <h1 className="ud-mb-8 ud-font-heading ud-text-[100px] ud-font-semibold ud-leading-none ud-text-dark dark:ud-text-white md:ud-text-[170px] md:ud-leading-none">
                404
              </h1>
              <h2 className="ud-mb-5 ud-font-heading ud-text-3xl ud-font-medium ud-text-dark dark:ud-text-white md:ud-text-4xl">
                Sorry, the page can't be found
              </h2>
              <p className="ud-text-base ud-mb-[30px] ud-font-medium ud-text-body-color-2 dark:ud-text-body-color sm:ud-text-lg lg:ud-text-base xl:ud-text-lg">
                The page you were looking for appears to have been moved,
                deleted or does not exist.
              </p>
              <Link
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                }}
                to="/"
                className="ud-inline-flex ud-items-center ud-justify-center ud-rounded ud-bg-primary ud-py-[14px] ud-px-8 ud-text-sm ud-font-semibold ud-text-white"
              >
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Error404;
