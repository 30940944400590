import React from "react";
import shape from "../../../images/shapes/newsletter-shape.svg";
import { newslatterData } from "./newsletterData";

const Newsletter = ({ background }) => {
  const { title, description, placeholder, button, icon } = newslatterData;
  return (
    <>
      <section id="newsletter" className="ud-relative ud-z-10">
        {background && (
          <div className="ud-absolute ud-top-0 ud-left-0 ud--z-10 ud-h-[120px] ud-w-full ud-bg-light-bg dark:ud-bg-[#14102C]"></div>
        )}
        <div className="ud-container">
          <div
            className="wow fadeInUp ud-relative ud-z-10 ud-overflow-hidden ud-rounded ud-bg-dark ud-p-8 sm:ud-p-12"
            data-wow-delay="0s"
          >
            <div className="ud--mx-4 ud-flex ud-flex-wrap ud-items-center">
              <div className="ud-w-full ud-px-4 lg:ud-w-1/2">
                <div className="ud-mb-10 lg:ud-mb-0">
                  <div className="ud-max-w-[500px]">
                    <h2 className="ud-mb-2 ud-text-3xl ud-font-bold ud-leading-tight ud-text-white md:ud-text-[45px]">
                      {title}
                    </h2>
                    <p className="ud-text-lg ud-font-medium ud-text-white">
                      {description}
                    </p>
                  </div>
                </div>
              </div>
              <div className="ud-w-full ud-px-3 lg:ud-w-1/2">
                <div>
                  <form className="ud-relative">
                    <input
                      type="email"
                      placeholder={placeholder}
                      className="ud-w-full ud-rounded-full ud-border ud-border-transparent ud-bg-white ud-py-5 ud-px-10 ud-text-base ud-font-medium ud-text-body-color-2 ud-outline-none dark:ud-text-body-color sm:ud-pr-24"
                    />
                    <button className="ud-right-[10px] ud-top-1/2 ud-mt-5 ud-inline-flex ud-h-12 ud-items-center ud-rounded-full ud-bg-primary ud-px-7 ud-text-base ud-font-medium ud-text-white hover:ud-bg-opacity-90 sm:ud-absolute sm:ud-mt-0 sm:ud--translate-y-1/2">
                      {button}
                      <span className="ud-pl-1">{icon}</span>
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="ud-absolute ud-top-0 ud-right-0 ud--z-10">
              <img src={shape} alt="shape" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Newsletter;
