import React from "react";
import FeaturesCard from "./FeaturesCard";

const Features = ({ data }) => {
  const { subtitle, title, description, cards } = data;
  return (
    <>
      <section id="features" className="ud-pt-[120px] ud-pb-16">
        <div className="ud-container">
          <div className="ud-mx-auto ud-mb-16 ud-max-w-[590px] ud-text-center md:ud-mb-20">
            <span className="ud-mb-3 ud-text-lg ud-font-bold ud-uppercase ud-text-primary sm:ud-text-xl">
              {subtitle}
            </span>
            <h2 className="ud-mb-3 ud-text-3xl ud-font-bold ud-leading-tight ud-text-black dark:ud-text-white md:ud-text-[45px]">
              {title}
            </h2>
            <p className="ud-text-lg ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
              {description}
            </p>
          </div>

          {/* Features Card Start */}
          <div className="ud--mx-4 ud-flex ud-flex-wrap">
            {cards.map((card) => (
              <FeaturesCard key={card.id} card={card} />
            ))}
          </div>

          {/* Features Card End */}
        </div>
      </section>
    </>
  );
};

export default Features;
