import React from "react";
import Breadcrumb from "../../share/Breadcrumb/Breadcrumb";
import Newsletter from "../../share/Newsletter/Newsletter";
import Blog from "./Blog";

import { blogData } from "./blogData";

const BlogGrid = () => {
  document.title = "Blog Grid - Crypto React";

  return (
    <>
      <Breadcrumb />
      <Blog data={blogData} />
      <Newsletter />
    </>
  );
};

export default BlogGrid;
