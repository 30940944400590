import React from "react";
import { Link } from "react-router-dom";
import shape1 from "../../../../images/shapes/hero-shape-1.svg";
import shape2 from "../../../../images/shapes/hero-shape-2.svg";
import Icon from "./Icon";
const Hero = (props) => {
  const { title, description, button, icons } = props.data;

  return (
    <>
      <section id="home" className="ud-relative ud-z-10 ud-pt-48 ud-pb-28">
        {/* Home page Background Shape Start */}
        <div
          className="ud-absolute ud-top-0 ud-left-0 ud--z-10 ud-h-full ud-w-full ud-opacity-20"
          style={{
            backgroundImage: `linear-gradient(180deg,#3e7dff 0%,rgba(62, 125, 255, 0) 100%)`,
          }}
        />
        <img
          src={shape1}
          alt=""
          className="ud-absolute ud-left-0 ud-top-0 ud--z-10"
        />
        <img
          src={shape2}
          alt=""
          className="ud-absolute ud-right-0 ud-top-0 ud--z-10"
        />
        {/* Home page Background Shape Start */}

        <div className="ud-container">
          <div className="ud--mx-4 ud-flex ud-flex-wrap">
            <div className="ud-w-full ud-px-4">
              <div className="ud-mx-auto ud-max-w-[720px] ud-text-center">
                <h1 className="ud-mb-4 ud-text-3xl ud-font-bold ud-leading-tight ud-text-black dark:ud-text-white md:ud-text-[45px]">
                  {title}
                </h1>
                <p className="ud-mx-auto ud-mb-4 ud-max-w-[620px] ud-text-lg ud-font-medium ud-text-body-color-2 dark:ud-text-white">
                  {description}
                </p>

                {/* Currency Icons Start */}
                <div className="ud--mx-1 ud-mb-10 ud-flex ud-flex-wrap ud-items-center ud-justify-center sm:ud--mx-2">
                  {icons.map((icon) => (
                    <Icon key={icon.id} icon={icon} />
                  ))}
                </div>
                {/* Currency Icons End */}

                <Link
                  to="#"
                  className="ud-rounded-full ud-bg-primary ud-py-3 ud-px-8 ud-text-base ud-font-semibold ud-text-white hover:ud-bg-opacity-90 dark:hover:bg-opacity-90"
                >
                  {button}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
