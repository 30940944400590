export const roadmapData = {
  subTitle: "ROADMAP",
  title: "The Timeline",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condimentum vel.",
  timeLineCard: [
    {
      id: "1",
      position: "left",
      date: "Feb 25,2020",
      cardDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condimentum vel",
      iconSvg: (
        <svg
          width="28"
          height="29"
          viewBox="0 0 28 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.5815 17.3879C25.7115 24.8885 18.1146 29.4533 10.6131 27.5829C3.11474 25.7129 -1.44998 18.1155 0.420775 10.6155C2.28989 3.11409 9.8868 -1.45098 17.386 0.418958C24.8869 2.28889 29.4514 9.8871 27.5813 17.3881L27.5814 17.3879H27.5815Z"
            fill="#F7931A"
          />
          <path
            d="M18.9713 12.0301C19.2001 10.454 18.0353 9.60675 16.4424 9.04155L16.9591 6.90652L15.6975 6.58268L15.1945 8.6615C14.8628 8.57628 14.5222 8.49599 14.1837 8.41639L14.6904 6.32384L13.4295 6L12.9125 8.13434C12.6381 8.06996 12.3685 8.00634 12.107 7.9393L12.1084 7.93259L10.3686 7.48503L10.033 8.87315C10.033 8.87315 10.969 9.09418 10.9493 9.10778C11.4602 9.23914 11.5526 9.58753 11.5372 9.86367L10.9486 12.296C10.9838 12.3052 11.0294 12.3185 11.0798 12.3393C11.0377 12.3286 10.9929 12.3168 10.9464 12.3054L10.1214 15.7127C10.059 15.8726 9.90051 16.1125 9.54333 16.0214C9.55597 16.0403 8.62637 15.7857 8.62637 15.7857L8 17.2734L9.64178 17.695C9.94721 17.7739 10.2465 17.8564 10.5413 17.9341L10.0192 20.0936L11.2794 20.4174L11.7964 18.2808C12.1406 18.3771 12.4747 18.4659 12.8018 18.5496L12.2865 20.6762L13.5482 21L14.0702 18.8445C16.2215 19.264 17.8391 19.0949 18.52 17.0903C19.0687 15.4764 18.4927 14.5455 17.3609 13.9385C18.1852 13.7426 18.8062 13.1841 18.9717 12.0303L18.9713 12.03L18.9713 12.0301ZM16.0888 16.194C15.6989 17.8079 13.0611 16.9355 12.2059 16.7167L12.8987 13.8557C13.7539 14.0757 16.4963 14.5109 16.0888 16.194H16.0888ZM16.4789 12.0068C16.1233 13.4748 13.9278 12.7289 13.2157 12.5461L13.8438 9.95132C14.556 10.1342 16.8494 10.4755 16.4791 12.0068H16.4789Z"
            fill="white"
          />
        </svg>
      ),
      name: "Bitcoin",
      value: "4.5%",
    },
    {
      id: "2",
      position: "right",
      date: "Jan 14,2021",
      cardDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condimentum vel",
      iconSvg: (
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14.28" cy="14.28" r="14.28" fill="#295ADA" />
          <g clipPath="url(#clip0_73_10326)">
            <path
              d="M14.4692 7L13.1848 7.73853L9.68479 9.76147L8.40039 10.5V17.5L9.68479 18.2385L13.2169 20.2615L14.5013 21L15.7857 20.2615L19.2536 18.2385L20.538 17.5V10.5L19.2536 9.76147L15.7536 7.73853L14.4692 7ZM10.9692 16.0229V11.9771L14.4692 9.95413L17.9692 11.9771V16.0229L14.4692 18.0459L10.9692 16.0229Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_73_10326">
              <rect
                width="12.1376"
                height="14"
                fill="white"
                transform="translate(8.40039 7)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      name: "ethereum",
      value: "2.5%",
    },
    {
      id: "3",
      position: "left",
      date: "Mar 30,2021",
      cardDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condimentum vel",
      iconSvg: (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14" cy="14" r="14" fill="#24292F" />{" "}
          <g clipPath="url(#clip0_73_10306)">
            {" "}
            <path
              d="M17.0343 8.79657L14.7432 12.2005C14.5847 12.4323 14.8894 12.7129 15.1088 12.5177L17.3633 10.5534C17.4243 10.5046 17.5096 10.5412 17.5096 10.6266V16.7635C17.5096 16.8489 17.3999 16.8855 17.3511 16.8245L10.5265 8.65017C10.3071 8.38176 9.99026 8.23535 9.63684 8.23535H9.39311C8.75939 8.23535 8.23535 8.75997 8.23535 9.40659V18.0445C8.23535 18.6911 8.75939 19.2157 9.40529 19.2157C9.80746 19.2157 10.1853 19.0083 10.4046 18.6545L12.6958 15.2506C12.8542 15.0188 12.5495 14.7382 12.3301 14.9334L10.0756 16.8855C10.0146 16.9343 9.92933 16.8977 9.92933 16.8123V10.6876C9.92933 10.6022 10.039 10.5656 10.0878 10.6266L16.9124 18.8009C17.1318 19.0693 17.4608 19.2157 17.8021 19.2157H18.0458C18.6917 19.2157 19.2157 18.6911 19.2157 18.0445V9.40659C19.2157 8.75997 18.6917 8.23535 18.0458 8.23535C17.6314 8.23535 17.2537 8.44276 17.0343 8.79657Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_73_10306">
              <rect
                width="10.9804"
                height="10.9804"
                fill="white"
                transform="translate(8.23535 8.23535)"
              />
            </clipPath>
          </defs>
        </svg>
      ),
      name: "chainlink",
      value: "4.5%",
    },
    {
      id: "4",
      position: "right",
      date: "Dec 19,2021",
      cardDescription:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condimentum vel",
      iconSvg: (
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14" cy="14" r="14" fill="#F14E51" />
          <path
            d="M20.8096 11.2176C20.1191 10.6117 19.1639 9.6864 18.386 9.03016L18.34 8.99953C18.2634 8.94108 18.177 8.89524 18.0845 8.86391C16.2087 8.53142 7.47873 6.9805 7.30841 7.00019C7.26069 7.00654 7.21507 7.02298 7.17492 7.04831L7.13119 7.08112C7.07734 7.1331 7.03645 7.19589 7.01151 7.26487L7 7.29331V7.44862V7.47268C7.98278 10.0736 11.8633 18.5938 12.6274 20.5931C12.6734 20.7288 12.7609 20.9869 12.9243 21H12.9611C13.0486 21 13.4215 20.5319 13.4215 20.5319C13.4215 20.5319 20.0869 12.8495 20.7612 12.0314C20.8485 11.9306 20.9256 11.8222 20.9914 11.7076C21.0082 11.618 21.0003 11.5257 20.9684 11.4399C20.9366 11.354 20.8818 11.2774 20.8096 11.2176ZM15.1315 12.1123L17.9763 9.87015L19.645 11.3314L15.1315 12.1123ZM14.0268 11.9657L9.12898 8.1508L17.0534 9.53984L14.0268 11.9657ZM14.4687 12.9654L19.4816 12.1976L13.7506 18.76L14.4687 12.9654ZM8.46382 8.53142L13.6171 12.6876L12.8714 18.7644L8.46382 8.53142Z"
            fill="white"
          />
        </svg>
      ),
      name: "Polygon",
      value: "8.5%",
    },
  ],
};
