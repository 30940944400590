export const contactData = {
  subTitle: "CONTACT US",
  title: "Let's talk about your problem.",
  address: [
    {
      id: "1",
      title: "Our Location",
      address: "401 Broadway, 24th Floor, Orchard Cloud View, London",
    },
    {
      id: "2",
      title: "Email Address",
      email1: "info@yourdomain.com",
      email2: "contact@yourdomain.com",
    },
    {
      id: "3",
      title: "Phone Number",
      phone1: "+990 846 73644",
      phone2: "+550 9475 4543",
    },
    {
      id: "4",
      title: "How Can We Help?",
      address: "Tell us your problem we will get back to you ASAP.",
    },
  ],
  formTitle: "Send us a Message",
  input: [
    {
      id: "1",
      label: "Full Name*",
      placeholder: "Enter your full name",
    },
    {
      id: "2",
      label: "Email Address*",
      placeholder: "Enter your Email address",
    },
  ],
  textAreaTitle: "Message*",
  textAreaPlaceholder: "Type your Message",
  button: "Send Message",
};
