import React from "react";

const Input = ({ input }) => {
  const { label, placeholder } = input;
  return (
    <>
      <label
        htmlFor="name"
        className="ud-mb-2 ud-block ud-text-sm ud-font-medium ud-text-dark dark:ud-text-white"
      >
        {label}
      </label>

      <input
        type="text"
        id="name"
        placeholder={placeholder}
        className="ud-w-full ud-rounded-md ud-border ud-border-[#E9E9E9] ud-border-opacity-50 ud-py-3 ud-px-5 ud-text-base ud-font-medium ud-text-body-color ud-outline-none focus:ud-border-primary dark:ud-border-opacity-20 dark:ud-bg-white dark:ud-bg-opacity-5 ud-mb-[20px]"
      />
    </>
  );
};

export default Input;
