import React from "react";

const FeaturesCard = ({ card }) => {
  const { image, cardTitle, cardDescription } = card;
  return (
    <>
      <div className="ud-w-full ud-px-4 md:ud-w-1/2 lg:ud-w-1/3">
        <div className="ud-mx-auto ud-mb-14 ud-max-w-[370px] ud-text-center">
          <div className="ud-mx-auto ud-mb-7 ud-flex ud-h-20 ud-w-20 ud-items-center ud-justify-center ud-rounded-full ud-bg-primary ud-text-white">
            <img src={image} alt="" />
          </div>

          <div>
            <h3 className="ud-mb-3 ud-text-xl ud-font-bold ud-text-black dark:ud-text-white sm:ud-text-3xl lg:ud-text-xl xl:ud-text-3xl">
              {cardTitle}
            </h3>

            <p className="ud-text-base ud-font-medium ud-text-body-color-2 dark:ud-text-body-color sm:ud-text-lg lg:ud-text-base xl:ud-text-lg">
              {cardDescription}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturesCard;
