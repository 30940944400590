import safeSecure from "../../../../images/features-icon/safe-secure.svg";
import earlyBonus from "../../../../images/features-icon/early-bonus.svg";
import universalAccess from "../../../../images/features-icon/universal-access.svg";
import secureStorage from "../../../../images/features-icon/secure-storage.svg";
import lowCost from "../../../../images/features-icon/low-cost.svg";
import severalProfit from "../../../../images/features-icon/several-profit.svg";

export const featuresData = {
  subtitle: "CRYPTO FEATURE",
  title: "Best Features",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condimentum vel.",
  cards: [
    {
      id: "1",
      image: safeSecure,
      cardTitle: "Safe & Secure",
      cardDescription:
        "Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod tempor labore labore labore et dolor .",
    },
    {
      id: "2",
      image: earlyBonus,
      cardTitle: "Early Bonus",
      cardDescription:
        "Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod tempor labore labore labore et dolor.",
    },
    {
      id: "3",
      image: universalAccess,
      cardTitle: "Universal Access",
      cardDescription:
        "Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod tempor labore labore labore et dolor .",
    },
    {
      id: "4",
      image: secureStorage,
      cardTitle: "Secure Storage",
      cardDescription:
        "Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod tempor labore labore labore et dolor .",
    },
    {
      id: "5",
      image: lowCost,
      cardTitle: "Low Cost",
      cardDescription:
        "Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod tempor labore labore labore et dolor .",
    },
    {
      id: "6",
      image: severalProfit,
      cardTitle: " Several Profit",
      cardDescription:
        "Lorem ipsum dolor sit amet consectetur elit, sed do eiusmod tempor labore labore labore et dolor .",
    },
  ],
};
