import image1 from "../../../../images/team/image-01.jpg";
import image2 from "../../../../images/team/image-02.jpg";
import image3 from "../../../../images/team/image-03.jpg";
import image4 from "../../../../images/team/image-04.jpg";

export const teamData = {
  subtitle: "Our Team",
  title: "Meet out Team",
  description:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed congue arcu, In et dignissim quam condimentum vel.",

  teamCard: [
    {
      id: "1",
      image: image1,
      name: "Matheus Ferrero",
      profession: "Marketing Expert",
      social: [
        {
          id: "1",
          name: "facebook",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M18.3337 10C18.3337 5.40002 14.6003 1.66669 10.0003 1.66669C5.40033 1.66669 1.66699 5.40002 1.66699 10C1.66699 14.0334 4.53366 17.3917 8.33366 18.1667V12.5H6.66699V10H8.33366V7.91669C8.33366 6.30835 9.64199 5.00002 11.2503 5.00002H13.3337V7.50002H11.667C11.2087 7.50002 10.8337 7.87502 10.8337 8.33335V10H13.3337V12.5H10.8337V18.2917C15.042 17.875 18.3337 14.325 18.3337 10Z" />
            </svg>
          ),
          href: "https://www.facebook.com/",
        },
        {
          id: "2",
          name: "twitter",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M18.7168 4.99998C18.0751 5.29165 17.3834 5.48331 16.6668 5.57498C17.4001 5.13331 17.9668 4.43331 18.2334 3.59165C17.5418 4.00831 16.7751 4.29998 15.9668 4.46665C15.3084 3.74998 14.3834 3.33331 13.3334 3.33331C11.3751 3.33331 9.77511 4.93331 9.77511 6.90831C9.77511 7.19165 9.80845 7.46665 9.86678 7.72498C6.90011 7.57498 4.25845 6.14998 2.50011 3.99165C2.19178 4.51665 2.01678 5.13331 2.01678 5.78331C2.01678 7.02498 2.64178 8.12498 3.60845 8.74998C3.01678 8.74998 2.46678 8.58331 1.98345 8.33331C1.98345 8.33331 1.98345 8.33331 1.98345 8.35831C1.98345 10.0916 3.21678 11.5416 4.85011 11.8666C4.55011 11.95 4.23345 11.9916 3.90845 11.9916C3.68345 11.9916 3.45845 11.9666 3.24178 11.925C3.69178 13.3333 5.00011 14.3833 6.57511 14.4083C5.35845 15.375 3.81678 15.9416 2.13345 15.9416C1.85011 15.9416 1.56678 15.925 1.28345 15.8916C2.86678 16.9083 4.75011 17.5 6.76678 17.5C13.3334 17.5 16.9418 12.05 16.9418 7.32498C16.9418 7.16665 16.9418 7.01665 16.9334 6.85831C17.6334 6.35831 18.2334 5.72498 18.7168 4.99998Z" />
            </svg>
          ),
          href: "",
        },
        {
          id: "3",
          name: "linkedin",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M15.8333 2.5C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H15.8333ZM15.4167 15.4167V11C15.4167 10.2795 15.1304 9.5885 14.621 9.07903C14.1115 8.56955 13.4205 8.28333 12.7 8.28333C11.9917 8.28333 11.1667 8.71667 10.7667 9.36667V8.44167H8.44167V15.4167H10.7667V11.3083C10.7667 10.6667 11.2833 10.1417 11.925 10.1417C12.2344 10.1417 12.5312 10.2646 12.75 10.4834C12.9688 10.7022 13.0917 10.9989 13.0917 11.3083V15.4167H15.4167ZM5.73333 7.13333C6.10464 7.13333 6.46073 6.98583 6.72328 6.72328C6.98583 6.46073 7.13333 6.10464 7.13333 5.73333C7.13333 4.95833 6.50833 4.325 5.73333 4.325C5.35982 4.325 5.0016 4.47338 4.73749 4.73749C4.47338 5.0016 4.325 5.35982 4.325 5.73333C4.325 6.50833 4.95833 7.13333 5.73333 7.13333ZM6.89167 15.4167V8.44167H4.58333V15.4167H6.89167Z" />
            </svg>
          ),
          href: "",
        },
        {
          id: "4",
          name: "youtube",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M8.33341 12.5L12.6584 10L8.33341 7.50002V12.5ZM17.9667 5.97502C18.0751 6.36669 18.1501 6.89169 18.2001 7.55835C18.2584 8.22502 18.2834 8.80002 18.2834 9.30002L18.3334 10C18.3334 11.825 18.2001 13.1667 17.9667 14.025C17.7584 14.775 17.2751 15.2584 16.5251 15.4667C16.1334 15.575 15.4167 15.65 14.3167 15.7C13.2334 15.7584 12.2417 15.7834 11.3251 15.7834L10.0001 15.8334C6.50841 15.8334 4.33341 15.7 3.47508 15.4667C2.72508 15.2584 2.24175 14.775 2.03341 14.025C1.92508 13.6334 1.85008 13.1084 1.80008 12.4417C1.74175 11.775 1.71675 11.2 1.71675 10.7L1.66675 10C1.66675 8.17502 1.80008 6.83335 2.03341 5.97502C2.24175 5.22502 2.72508 4.74169 3.47508 4.53335C3.86675 4.42502 4.58341 4.35002 5.68341 4.30002C6.76675 4.24169 7.75841 4.21669 8.67508 4.21669L10.0001 4.16669C13.4917 4.16669 15.6667 4.30002 16.5251 4.53335C17.2751 4.74169 17.7584 5.22502 17.9667 5.97502Z" />
            </svg>
          ),
          href: "",
        },
      ],
    },
    {
      id: "2",
      image: image2,
      name: "Eva Hudson",
      profession: "Blockchain Developer",
      social: [
        {
          id: "1",
          name: "facebook",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M18.3337 10C18.3337 5.40002 14.6003 1.66669 10.0003 1.66669C5.40033 1.66669 1.66699 5.40002 1.66699 10C1.66699 14.0334 4.53366 17.3917 8.33366 18.1667V12.5H6.66699V10H8.33366V7.91669C8.33366 6.30835 9.64199 5.00002 11.2503 5.00002H13.3337V7.50002H11.667C11.2087 7.50002 10.8337 7.87502 10.8337 8.33335V10H13.3337V12.5H10.8337V18.2917C15.042 17.875 18.3337 14.325 18.3337 10Z" />
            </svg>
          ),
          href: "https://www.facebook.com/",
        },
        {
          id: "2",
          name: "twitter",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M18.7168 4.99998C18.0751 5.29165 17.3834 5.48331 16.6668 5.57498C17.4001 5.13331 17.9668 4.43331 18.2334 3.59165C17.5418 4.00831 16.7751 4.29998 15.9668 4.46665C15.3084 3.74998 14.3834 3.33331 13.3334 3.33331C11.3751 3.33331 9.77511 4.93331 9.77511 6.90831C9.77511 7.19165 9.80845 7.46665 9.86678 7.72498C6.90011 7.57498 4.25845 6.14998 2.50011 3.99165C2.19178 4.51665 2.01678 5.13331 2.01678 5.78331C2.01678 7.02498 2.64178 8.12498 3.60845 8.74998C3.01678 8.74998 2.46678 8.58331 1.98345 8.33331C1.98345 8.33331 1.98345 8.33331 1.98345 8.35831C1.98345 10.0916 3.21678 11.5416 4.85011 11.8666C4.55011 11.95 4.23345 11.9916 3.90845 11.9916C3.68345 11.9916 3.45845 11.9666 3.24178 11.925C3.69178 13.3333 5.00011 14.3833 6.57511 14.4083C5.35845 15.375 3.81678 15.9416 2.13345 15.9416C1.85011 15.9416 1.56678 15.925 1.28345 15.8916C2.86678 16.9083 4.75011 17.5 6.76678 17.5C13.3334 17.5 16.9418 12.05 16.9418 7.32498C16.9418 7.16665 16.9418 7.01665 16.9334 6.85831C17.6334 6.35831 18.2334 5.72498 18.7168 4.99998Z" />
            </svg>
          ),
          href: "",
        },
        {
          id: "3",
          name: "linkedin",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M15.8333 2.5C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H15.8333ZM15.4167 15.4167V11C15.4167 10.2795 15.1304 9.5885 14.621 9.07903C14.1115 8.56955 13.4205 8.28333 12.7 8.28333C11.9917 8.28333 11.1667 8.71667 10.7667 9.36667V8.44167H8.44167V15.4167H10.7667V11.3083C10.7667 10.6667 11.2833 10.1417 11.925 10.1417C12.2344 10.1417 12.5312 10.2646 12.75 10.4834C12.9688 10.7022 13.0917 10.9989 13.0917 11.3083V15.4167H15.4167ZM5.73333 7.13333C6.10464 7.13333 6.46073 6.98583 6.72328 6.72328C6.98583 6.46073 7.13333 6.10464 7.13333 5.73333C7.13333 4.95833 6.50833 4.325 5.73333 4.325C5.35982 4.325 5.0016 4.47338 4.73749 4.73749C4.47338 5.0016 4.325 5.35982 4.325 5.73333C4.325 6.50833 4.95833 7.13333 5.73333 7.13333ZM6.89167 15.4167V8.44167H4.58333V15.4167H6.89167Z" />
            </svg>
          ),
          href: "",
        },
        {
          id: "4",
          name: "youtube",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M8.33341 12.5L12.6584 10L8.33341 7.50002V12.5ZM17.9667 5.97502C18.0751 6.36669 18.1501 6.89169 18.2001 7.55835C18.2584 8.22502 18.2834 8.80002 18.2834 9.30002L18.3334 10C18.3334 11.825 18.2001 13.1667 17.9667 14.025C17.7584 14.775 17.2751 15.2584 16.5251 15.4667C16.1334 15.575 15.4167 15.65 14.3167 15.7C13.2334 15.7584 12.2417 15.7834 11.3251 15.7834L10.0001 15.8334C6.50841 15.8334 4.33341 15.7 3.47508 15.4667C2.72508 15.2584 2.24175 14.775 2.03341 14.025C1.92508 13.6334 1.85008 13.1084 1.80008 12.4417C1.74175 11.775 1.71675 11.2 1.71675 10.7L1.66675 10C1.66675 8.17502 1.80008 6.83335 2.03341 5.97502C2.24175 5.22502 2.72508 4.74169 3.47508 4.53335C3.86675 4.42502 4.58341 4.35002 5.68341 4.30002C6.76675 4.24169 7.75841 4.21669 8.67508 4.21669L10.0001 4.16669C13.4917 4.16669 15.6667 4.30002 16.5251 4.53335C17.2751 4.74169 17.7584 5.22502 17.9667 5.97502Z" />
            </svg>
          ),
          href: "",
        },
      ],
    },
    {
      id: "3",
      image: image3,
      name: "Jackie Sanders",
      profession: "Creative Designer",
      social: [
        {
          id: "1",
          name: "facebook",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M18.3337 10C18.3337 5.40002 14.6003 1.66669 10.0003 1.66669C5.40033 1.66669 1.66699 5.40002 1.66699 10C1.66699 14.0334 4.53366 17.3917 8.33366 18.1667V12.5H6.66699V10H8.33366V7.91669C8.33366 6.30835 9.64199 5.00002 11.2503 5.00002H13.3337V7.50002H11.667C11.2087 7.50002 10.8337 7.87502 10.8337 8.33335V10H13.3337V12.5H10.8337V18.2917C15.042 17.875 18.3337 14.325 18.3337 10Z" />
            </svg>
          ),
          href: "https://www.facebook.com/",
        },
        {
          id: "2",
          name: "twitter",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M18.7168 4.99998C18.0751 5.29165 17.3834 5.48331 16.6668 5.57498C17.4001 5.13331 17.9668 4.43331 18.2334 3.59165C17.5418 4.00831 16.7751 4.29998 15.9668 4.46665C15.3084 3.74998 14.3834 3.33331 13.3334 3.33331C11.3751 3.33331 9.77511 4.93331 9.77511 6.90831C9.77511 7.19165 9.80845 7.46665 9.86678 7.72498C6.90011 7.57498 4.25845 6.14998 2.50011 3.99165C2.19178 4.51665 2.01678 5.13331 2.01678 5.78331C2.01678 7.02498 2.64178 8.12498 3.60845 8.74998C3.01678 8.74998 2.46678 8.58331 1.98345 8.33331C1.98345 8.33331 1.98345 8.33331 1.98345 8.35831C1.98345 10.0916 3.21678 11.5416 4.85011 11.8666C4.55011 11.95 4.23345 11.9916 3.90845 11.9916C3.68345 11.9916 3.45845 11.9666 3.24178 11.925C3.69178 13.3333 5.00011 14.3833 6.57511 14.4083C5.35845 15.375 3.81678 15.9416 2.13345 15.9416C1.85011 15.9416 1.56678 15.925 1.28345 15.8916C2.86678 16.9083 4.75011 17.5 6.76678 17.5C13.3334 17.5 16.9418 12.05 16.9418 7.32498C16.9418 7.16665 16.9418 7.01665 16.9334 6.85831C17.6334 6.35831 18.2334 5.72498 18.7168 4.99998Z" />
            </svg>
          ),
          href: "",
        },
        {
          id: "3",
          name: "linkedin",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M15.8333 2.5C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H15.8333ZM15.4167 15.4167V11C15.4167 10.2795 15.1304 9.5885 14.621 9.07903C14.1115 8.56955 13.4205 8.28333 12.7 8.28333C11.9917 8.28333 11.1667 8.71667 10.7667 9.36667V8.44167H8.44167V15.4167H10.7667V11.3083C10.7667 10.6667 11.2833 10.1417 11.925 10.1417C12.2344 10.1417 12.5312 10.2646 12.75 10.4834C12.9688 10.7022 13.0917 10.9989 13.0917 11.3083V15.4167H15.4167ZM5.73333 7.13333C6.10464 7.13333 6.46073 6.98583 6.72328 6.72328C6.98583 6.46073 7.13333 6.10464 7.13333 5.73333C7.13333 4.95833 6.50833 4.325 5.73333 4.325C5.35982 4.325 5.0016 4.47338 4.73749 4.73749C4.47338 5.0016 4.325 5.35982 4.325 5.73333C4.325 6.50833 4.95833 7.13333 5.73333 7.13333ZM6.89167 15.4167V8.44167H4.58333V15.4167H6.89167Z" />
            </svg>
          ),
          href: "",
        },
        {
          id: "4",
          name: "youtube",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M8.33341 12.5L12.6584 10L8.33341 7.50002V12.5ZM17.9667 5.97502C18.0751 6.36669 18.1501 6.89169 18.2001 7.55835C18.2584 8.22502 18.2834 8.80002 18.2834 9.30002L18.3334 10C18.3334 11.825 18.2001 13.1667 17.9667 14.025C17.7584 14.775 17.2751 15.2584 16.5251 15.4667C16.1334 15.575 15.4167 15.65 14.3167 15.7C13.2334 15.7584 12.2417 15.7834 11.3251 15.7834L10.0001 15.8334C6.50841 15.8334 4.33341 15.7 3.47508 15.4667C2.72508 15.2584 2.24175 14.775 2.03341 14.025C1.92508 13.6334 1.85008 13.1084 1.80008 12.4417C1.74175 11.775 1.71675 11.2 1.71675 10.7L1.66675 10C1.66675 8.17502 1.80008 6.83335 2.03341 5.97502C2.24175 5.22502 2.72508 4.74169 3.47508 4.53335C3.86675 4.42502 4.58341 4.35002 5.68341 4.30002C6.76675 4.24169 7.75841 4.21669 8.67508 4.21669L10.0001 4.16669C13.4917 4.16669 15.6667 4.30002 16.5251 4.53335C17.2751 4.74169 17.7584 5.22502 17.9667 5.97502Z" />
            </svg>
          ),
          href: "",
        },
      ],
    },
    {
      id: "4",
      image: image4,
      name: "Jackie Sanders",
      profession: "SEO Expert",
      social: [
        {
          id: "1",
          name: "facebook",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M18.3337 10C18.3337 5.40002 14.6003 1.66669 10.0003 1.66669C5.40033 1.66669 1.66699 5.40002 1.66699 10C1.66699 14.0334 4.53366 17.3917 8.33366 18.1667V12.5H6.66699V10H8.33366V7.91669C8.33366 6.30835 9.64199 5.00002 11.2503 5.00002H13.3337V7.50002H11.667C11.2087 7.50002 10.8337 7.87502 10.8337 8.33335V10H13.3337V12.5H10.8337V18.2917C15.042 17.875 18.3337 14.325 18.3337 10Z" />
            </svg>
          ),
          href: "https://www.facebook.com/",
        },
        {
          id: "2",
          name: "twitter",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M18.7168 4.99998C18.0751 5.29165 17.3834 5.48331 16.6668 5.57498C17.4001 5.13331 17.9668 4.43331 18.2334 3.59165C17.5418 4.00831 16.7751 4.29998 15.9668 4.46665C15.3084 3.74998 14.3834 3.33331 13.3334 3.33331C11.3751 3.33331 9.77511 4.93331 9.77511 6.90831C9.77511 7.19165 9.80845 7.46665 9.86678 7.72498C6.90011 7.57498 4.25845 6.14998 2.50011 3.99165C2.19178 4.51665 2.01678 5.13331 2.01678 5.78331C2.01678 7.02498 2.64178 8.12498 3.60845 8.74998C3.01678 8.74998 2.46678 8.58331 1.98345 8.33331C1.98345 8.33331 1.98345 8.33331 1.98345 8.35831C1.98345 10.0916 3.21678 11.5416 4.85011 11.8666C4.55011 11.95 4.23345 11.9916 3.90845 11.9916C3.68345 11.9916 3.45845 11.9666 3.24178 11.925C3.69178 13.3333 5.00011 14.3833 6.57511 14.4083C5.35845 15.375 3.81678 15.9416 2.13345 15.9416C1.85011 15.9416 1.56678 15.925 1.28345 15.8916C2.86678 16.9083 4.75011 17.5 6.76678 17.5C13.3334 17.5 16.9418 12.05 16.9418 7.32498C16.9418 7.16665 16.9418 7.01665 16.9334 6.85831C17.6334 6.35831 18.2334 5.72498 18.7168 4.99998Z" />
            </svg>
          ),
          href: "",
        },
        {
          id: "3",
          name: "linkedin",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M15.8333 2.5C16.2754 2.5 16.6993 2.67559 17.0118 2.98816C17.3244 3.30072 17.5 3.72464 17.5 4.16667V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V4.16667C2.5 3.72464 2.67559 3.30072 2.98816 2.98816C3.30072 2.67559 3.72464 2.5 4.16667 2.5H15.8333ZM15.4167 15.4167V11C15.4167 10.2795 15.1304 9.5885 14.621 9.07903C14.1115 8.56955 13.4205 8.28333 12.7 8.28333C11.9917 8.28333 11.1667 8.71667 10.7667 9.36667V8.44167H8.44167V15.4167H10.7667V11.3083C10.7667 10.6667 11.2833 10.1417 11.925 10.1417C12.2344 10.1417 12.5312 10.2646 12.75 10.4834C12.9688 10.7022 13.0917 10.9989 13.0917 11.3083V15.4167H15.4167ZM5.73333 7.13333C6.10464 7.13333 6.46073 6.98583 6.72328 6.72328C6.98583 6.46073 7.13333 6.10464 7.13333 5.73333C7.13333 4.95833 6.50833 4.325 5.73333 4.325C5.35982 4.325 5.0016 4.47338 4.73749 4.73749C4.47338 5.0016 4.325 5.35982 4.325 5.73333C4.325 6.50833 4.95833 7.13333 5.73333 7.13333ZM6.89167 15.4167V8.44167H4.58333V15.4167H6.89167Z" />
            </svg>
          ),
          href: "",
        },
        {
          id: "4",
          name: "youtube",
          icon: (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              className="ud-fill-current"
            >
              <path d="M8.33341 12.5L12.6584 10L8.33341 7.50002V12.5ZM17.9667 5.97502C18.0751 6.36669 18.1501 6.89169 18.2001 7.55835C18.2584 8.22502 18.2834 8.80002 18.2834 9.30002L18.3334 10C18.3334 11.825 18.2001 13.1667 17.9667 14.025C17.7584 14.775 17.2751 15.2584 16.5251 15.4667C16.1334 15.575 15.4167 15.65 14.3167 15.7C13.2334 15.7584 12.2417 15.7834 11.3251 15.7834L10.0001 15.8334C6.50841 15.8334 4.33341 15.7 3.47508 15.4667C2.72508 15.2584 2.24175 14.775 2.03341 14.025C1.92508 13.6334 1.85008 13.1084 1.80008 12.4417C1.74175 11.775 1.71675 11.2 1.71675 10.7L1.66675 10C1.66675 8.17502 1.80008 6.83335 2.03341 5.97502C2.24175 5.22502 2.72508 4.74169 3.47508 4.53335C3.86675 4.42502 4.58341 4.35002 5.68341 4.30002C6.76675 4.24169 7.75841 4.21669 8.67508 4.21669L10.0001 4.16669C13.4917 4.16669 15.6667 4.30002 16.5251 4.53335C17.2751 4.74169 17.7584 5.22502 17.9667 5.97502Z" />
            </svg>
          ),
          href: "",
        },
      ],
    },
  ],
};
