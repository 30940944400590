import React, { useState } from "react";
import shape1 from "../../../../images/shapes/faq-shape-1.svg";
import shape2 from "../../../../images/shapes/faq-shape-2.svg";

const FaqQus = ({ data }) => {
  const [openFaq1, setOpenFaq1] = useState(false);
  const [openFaq2, setOpenFaq2] = useState(false);
  const [openFaq3, setOpenFaq3] = useState(false);
  const [openFaq4, setOpenFaq4] = useState(false);

  const { subTitle, title, description, faq1, faq2, faq3, faq4 } = data;

  return (
    <>
      <section className="ud-relative ud-z-10 ud-bg-light-bg ud-py-24 dark:ud-bg-[#14102C]">
        <div className="ud-container">
          <div
            className="wow fadeInUp ud-mx-auto ud-mb-16 ud-max-w-[630px] ud-text-center md:ud-mb-20"
            data-wow-delay="0s"
          >
            <span className="ud-mb-3 ud-text-lg ud-font-bold ud-uppercase ud-text-primary sm:ud-text-xl">
              {subTitle}
            </span>
            <h2 className="ud-mb-3 ud-text-3xl ud-font-bold ud-leading-tight ud-text-black dark:ud-text-white md:ud-text-[45px]">
              {title}
            </h2>
            <p className="ud-mx-auto ud-max-w-[590px] ud-text-lg ud-font-medium ud-text-body-color-2 dark:ud-text-body-color">
              {description}
            </p>
          </div>

          <div className="ud--mx-4 ud-flex ud-flex-wrap ud-justify-center">
            <div className="ud-w-full ud-px-4 lg:ud-w-9/12 xl:ud-w-8/12">
              <div
                className="single-faq wow fadeInUp ud-mb-10 ud-rounded-lg ud-bg-white ud-py-6 ud-px-7 dark:ud-bg-dark md:ud-py-8 md:ud-px-10"
                data-wow-delay="0s"
              >
                <button
                  // @click="openFaq1 = !openFaq1"
                  onClick={() => setOpenFaq1(!openFaq1)}
                  className="faq-btn ud-flex ud-w-full ud-items-center ud-justify-between ud-text-left"
                >
                  <h3 className="ud-mr-2 ud-text-base ud-font-bold ud-text-dark dark:ud-text-white sm:ud-text-lg md:ud-text-xl">
                    {faq1.Qus}
                  </h3>
                  <span
                    className={`icon ud-inline-flex ud-h-5 ud-w-full ud-max-w-[20px] ud-items-center ud-justify-center ud-rounded ud-bg-body-color-2 ud-text-white dark:ud-text-black dark:ud-bg-body-color ud-text-lg ud-font-semibold ${
                      openFaq1 && `ud-rotate-180`
                    } `}
                    //   :className="openFaq1 && 'ud-rotate-180' "
                  >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_50_132)">
                        <path
                          d="M8.82033 1.91065L4.99951 5.73146L1.17869 1.91064L-0.000488487 3.08978L4.99951 8.08978L9.99951 3.08979L8.82033 1.91065Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_50_132">
                          <rect
                            width="10"
                            height="10"
                            fill="white"
                            transform="translate(-0.000488281 0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </button>
                {openFaq1 && (
                  <div className="faq-content">
                    <p className="ud-text-relaxed ud-pt-6 ud-text-base ud-text-body-color-2 dark:ud-text-body-color">
                      {faq1.ans}
                    </p>
                  </div>
                )}
              </div>
              <div
                className="single-faq wow fadeInUp ud-mb-10 ud-rounded-lg ud-bg-white ud-py-6 ud-px-7 dark:ud-bg-dark md:ud-py-8 md:ud-px-10"
                data-wow-delay="0s"
              >
                <button
                  // @click="openFaq2 = !openFaq2"
                  onClick={() => setOpenFaq2(!openFaq2)}
                  className="faq-btn ud-flex ud-w-full ud-items-center ud-justify-between ud-text-left"
                >
                  <h3 className="ud-mr-2 ud-text-base ud-font-bold ud-text-dark dark:ud-text-white sm:ud-text-lg md:ud-text-xl">
                    {faq2.Qus}
                  </h3>
                  <span
                    className={`icon ud-inline-flex ud-h-5 ud-w-full ud-max-w-[20px] ud-items-center ud-justify-center ud-rounded ud-bg-body-color-2 ud-text-white dark:ud-text-black dark:ud-bg-body-color ud-text-lg ud-font-semibold ${
                      openFaq2 && `ud-rotate-180`
                    } `}
                  >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_50_132)">
                        <path
                          d="M8.82033 1.91065L4.99951 5.73146L1.17869 1.91064L-0.000488487 3.08978L4.99951 8.08978L9.99951 3.08979L8.82033 1.91065Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_50_132">
                          <rect
                            width="10"
                            height="10"
                            fill="white"
                            transform="translate(-0.000488281 0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </button>
                {openFaq2 && (
                  <div className="faq-content">
                    <p className="ud-text-relaxed ud-pt-6 ud-text-base ud-text-body-color-2 dark:ud-text-body-color">
                      {faq2.ans}
                    </p>
                  </div>
                )}
              </div>
              <div
                className="single-faq wow fadeInUp ud-mb-10 ud-rounded-lg ud-bg-white ud-py-6 ud-px-7 dark:ud-bg-dark md:ud-py-8 md:ud-px-10"
                data-wow-delay="0s"
              >
                <button
                  // @click="openFaq3 = !openFaq3"
                  onClick={() => setOpenFaq3(!openFaq3)}
                  className="faq-btn ud-flex ud-w-full ud-items-center ud-justify-between ud-text-left"
                >
                  <h3 className="ud-mr-2 ud-text-base ud-font-bold ud-text-dark dark:ud-text-white sm:ud-text-lg md:ud-text-xl">
                    {faq3.Qus}
                  </h3>
                  <span
                    className={`icon ud-inline-flex ud-h-5 ud-w-full ud-max-w-[20px] ud-items-center ud-justify-center ud-rounded ud-bg-body-color-2 ud-text-white dark:ud-text-black dark:ud-bg-body-color ud-text-lg ud-font-semibold ${
                      openFaq3 && `ud-rotate-180`
                    } `}
                  >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_50_132)">
                        <path
                          d="M8.82033 1.91065L4.99951 5.73146L1.17869 1.91064L-0.000488487 3.08978L4.99951 8.08978L9.99951 3.08979L8.82033 1.91065Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_50_132">
                          <rect
                            width="10"
                            height="10"
                            fill="white"
                            transform="translate(-0.000488281 0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </button>
                {openFaq3 && (
                  <div className="faq-content">
                    <p className="ud-text-relaxed ud-pt-6 ud-text-base ud-text-body-color-2 dark:ud-text-body-color">
                      {faq3.ans}
                    </p>
                  </div>
                )}
              </div>
              <div
                className="single-faq wow fadeInUp ud-mb-10 ud-rounded-lg ud-bg-white ud-py-6 ud-px-7 dark:ud-bg-dark md:ud-py-8 md:ud-px-10"
                data-wow-delay="0s"
              >
                <button
                  // @click="openFaq4 = !openFaq4"
                  onClick={() => setOpenFaq4(!openFaq4)}
                  className="faq-btn ud-flex ud-w-full ud-items-center ud-justify-between ud-text-left"
                >
                  <h3 className="ud-mr-2 ud-text-base ud-font-bold ud-text-dark dark:ud-text-white sm:ud-text-lg md:ud-text-xl">
                    {faq4.Qus}
                  </h3>
                  <span
                    className={`icon ud-inline-flex ud-h-5 ud-w-full ud-max-w-[20px] ud-items-center ud-justify-center ud-rounded ud-bg-body-color-2 ud-text-white dark:ud-text-black dark:ud-bg-body-color ud-text-lg ud-font-semibold ${
                      openFaq4 && `ud-rotate-180`
                    } `}
                  >
                    <svg
                      width="10"
                      height="10"
                      viewBox="0 0 10 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_50_132)">
                        <path
                          d="M8.82033 1.91065L4.99951 5.73146L1.17869 1.91064L-0.000488487 3.08978L4.99951 8.08978L9.99951 3.08979L8.82033 1.91065Z"
                          fill="currentColor"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_50_132">
                          <rect
                            width="10"
                            height="10"
                            fill="white"
                            transform="translate(-0.000488281 0.000488281)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                </button>
                {openFaq4 && (
                  <div className="faq-content">
                    <p className="ud-text-relaxed ud-pt-6 ud-text-base ud-text-body-color-2 dark:ud-text-body-color">
                      {faq4.ans}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="ud-absolute ud-left-0 ud--bottom-36 ud--z-10">
          <img src={shape1} alt="shape" />
        </div>
        <div className="ud-absolute ud-right-0 ud--top-36 ud--z-10">
          <img src={shape2} alt="shape" />
        </div>
      </section>
    </>
  );
};

export default FaqQus;
