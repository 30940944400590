import React from "react";
import SigninSection from "./SigninSection";
import { signinData } from "./signinData";

const Signin = () => {
  document.title = "Sign In - Crypto React";

  return (
    <>
      <SigninSection data={signinData} />
    </>
  );
};

export default Signin;
